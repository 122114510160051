import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { Filial } from 'src/app/filiais/shared/filiais';
import { ViewService } from 'src/app/shared/services/view.service';
import { environment } from 'src/environments/environment';
import {Cliente} from '../../clientes/shared/cliente';

declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class StartupService {
  private filialId: number;

  constructor(
    private httpClient: HttpClient,
    private lsService: LocalStorageService,
    private viewService: ViewService
  ) {}

  private possuiCdSelecionado() {
    return this.lsService.keys().indexOf('cdSelecionado') > -1;
  }

  private getCdSelecionado() {
    return this.lsService.get('cdSelecionado');
  }

  private setCdSelecionado(loja) {
    this.lsService.set('loja', loja);
    this.lsService.set('cdSelecionado', loja.id);
  }

  private getFilialJson() {
    return this.httpClient
      .get(window.location.origin + '/assets/json/filial.json')
      .toPromise()
      .then((filialConfig: any) => {
        this.filialId = filialConfig.id;
        return filialConfig;
      });
  }

  private buscaFilial(additionalData) {
    return this.getFilialJson()
      .then(() => {
        return this.httpClient
          .get(`${environment.apiUrl}/loja/filiais/${this.filialId}`)
          .toPromise();
      })
      .then((res: any) => {
        Object.assign(res.data, additionalData);
        this.salvaFilial(res.data);
        return res.data;
      });
  }

  private buscaCd(cdId: number) {
    const CD_DETAIL_URL = `loja/centros_distribuicoes/${cdId}`;
    return this.httpClient
      .get(`${environment.apiUrl}/${CD_DETAIL_URL}`)
      .toPromise();
  }

  private salvaFilial(filial: Filial) {
    const filialString = JSON.stringify(filial);
    sessionStorage.setItem('vip-filial', btoa(filialString));
  }

  private salvarToken(token) {
    this.lsService.set('auth', token);
  }

  private autenticacaoApi() {
    return this.httpClient
      .post(`${this.getApiUrl()}/auth/loja/login`, {
        domain: this.getHostname(),
        username: 'loja',
        key: 'df072f85df9bf7dd71b6811c34bdbaa4f219d98775b56cff9dfa5f8ca1bf8469'
      })
      .toPromise()
      .then((res: any) => {
        this.salvarToken(res.data);
        return res;
      });
  }

  private getCdId(filial) {
    return this.possuiCdSelecionado()
      ? this.getCdSelecionado()
      : filial.centro_distribuicao_padrao_id;
  }

  private getHostname() {
    let hostname = window.location.hostname;
    hostname = hostname.startsWith('www') ? hostname.replace('www.', '') : hostname;
    return hostname;
  }

  private getWsUrl() {
    if (environment.production) {
      const hostname = this.getHostname();
      return `${window.location.protocol}//ws.${hostname}/loja`;
    }
    return environment.wsUrl;
  }

  private getApiUrl() {
    if (environment.apiUrl) {
      return environment.apiUrl;
    }
    const hostname = this.getHostname();
    return `${window.location.protocol}//api.${hostname}/v1`;
  }

  private getApiIntegracaoUrl() {
    if (environment.apiIntegracaoUrl) {
      return environment.apiIntegracaoUrl;
    }
    const hostname = this.getHostname();
    return `${window.location.protocol}//api-integracao.${hostname}`;
  }

  private getMainDomain() {
    if ('main_domain' in environment) {
      return environment.main_domain;
    }
    return window.location.hostname.replace('www.', '');
  }

  private setInfoEnvironment() {
    environment.apiUrl = this.getApiUrl();
    environment.wsUrl = this.getWsUrl();
    environment.apiIntegracaoUrl = this.getApiIntegracaoUrl();
    environment.main_domain = this.getMainDomain();
  }

  private getOrganizacaoId() {
    if ( !this.lsService.get('organizacao') ) {
      this.httpClient
      .get(`${this.getApiUrl()}/organizacao`)
      .toPromise()
      .then((res: any) => {
        this.lsService.set('organizacao', res.data);
      });
    }
  }

  load() {
    this.setInfoEnvironment();

    this.getOrganizacaoId();

    return new Promise((resolve, reject) => {
      let fidelidade = {};

      this.autenticacaoApi()
      .then(() => {
        return this.buscaFilial({fidelidade});
      })
      .then((filial) => {
        window.FILIAL = filial;
        environment.filialId = this.filialId;

        const cdId = this.getCdId(filial);

        return this.buscaCd(cdId);
      })
      .then((res: any) => {
        this.lsService.set('loja', res.data);
        return this.verificaParametrosURL().then(() => {
          resolve(true);
        });
      })
      .catch(error => {
        reject(error);
      });
    });
  }

  private logoutCliente() {
    return new Promise((resolve, reject) => {
      const token = this.lsService.get('token');
      if (token) {
        const headers = {
          Authorization: `Basic ${token}`
        };
        return this.httpClient.post('/clientes/logout/', [], {headers}).toPromise().then(() => {
          if (!!this.lsService.get('operador')) {
            this.lsService.remove('token');
            this.lsService.remove('cliente');
          } else {
            this.lsService.clearAll('^((?!(loja|auth)).)*$');
          }
          resolve(true);
        }).catch(reject);
      } else {
        resolve(true);
      }
    });
  }

  private getHeaders(token?: string): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + (token || this.getUserApiToken()));
    return headers;
  }

  public getUserApiToken() {
    return this.lsService.get('tokenUsuarioApi');
  }

  public setUserApiToken(token: string) {
    return this.lsService.set('tokenUsuarioApi', token);
  }

  private loginClienteViaToken(token: string, cliente: string) {
    this.setUserApiToken(token);
    const data = {
      cliente,
      filial_id: environment.filialId
    };
    const options: any = {
      headers: this.getHeaders(),
    };
    return this.logoutCliente().then( () => {
      const URI = `user_api/cliente/logar`;
      return this.httpClient
      .post(`${environment.apiUrl}/loja/${URI}`, data, options)
      .toPromise()
      .then((resp: any) => {
        if (resp.success) {
          this.afterLoginCliente(resp.data);
          return true;
        }
        return false;
      }).catch( () => {});
    });
  }

  private afterLoginCliente(token: string) {
    this.lsService.set('date', new Date().getTime());
    this.lsService.set('token', token);
  }

  private getClienteBase64(urlParams) {
    try {
      return atob(urlParams.get('cliente'));
    } catch (_) {
      return false;
    }
  }

  private verificaParametrosURL() {
    return new Promise((resolve, reject) => {
      const urlParams = new URLSearchParams(window.location.search);
      const inApp = urlParams.get('inApp') || false;
      this.viewService.setInAppMode(inApp);
      const token = urlParams.get('token');
      const cliente = this.getClienteBase64(urlParams);

      if (inApp && token && cliente) {
        this.loginClienteViaToken(token, cliente).then(() => {
          resolve(true);
        }).catch(reject);
      } else if (inApp) {
        this.logoutCliente().then(()=> {
          resolve(true);
        }).catch(reject);
      } else {
        resolve(true);
      }
    });
  }
}
