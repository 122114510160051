import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ImageService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.locals = {
            base: '',
            produtos: '',
            placeholders: '',
            categorias: '',
        };
        this.loaded = false;
        this.getLocals();
    }
    getLocals() {
        if (this.loaded) {
            return Promise.resolve();
        }
        else {
            return this.httpClient
                .get(environment.apiUrl + '/loja/arquivos/localizacao')
                .toPromise()
                .then((res) => {
                const locals = res.data || [];
                this.loaded = true;
                if (locals.length > 0) {
                    locals.forEach((local) => {
                        this.setProducts(local);
                        this.setBase(local);
                        this.setPlaceholders(local);
                        this.setCategorias(local);
                    });
                }
            });
        }
    }
    getBasePath() {
        return `${this.locals.base}/files/`;
    }
    getPlaceholders() {
        return this.locals.placeholders;
    }
    getPathCategoria(imagem) {
        return `${this.locals.categorias}/${imagem}`;
    }
    getPathPlaceholderBySize(size) {
        return `${this.locals.placeholders}/${size}.png`;
    }
    getPathProductImageBySize(imagem, size) {
        return `${this.locals.produtos}/${size}x${size}/${imagem}`;
    }
    isSameLocalModel(local, model) {
        return local.model.toLowerCase() === model;
    }
    setProducts(local) {
        if (this.isSameLocalModel(local, 'produto')) {
            this.locals.produtos = local.localizacao;
        }
    }
    setBase(local) {
        if (this.isSameLocalModel(local, 's3')) {
            this.locals.base = local.localizacao;
        }
    }
    setPlaceholders(local) {
        if (this.isSameLocalModel(local, 'placeholder')) {
            this.locals.placeholders = local.localizacao;
        }
    }
    setCategorias(local) {
        if (this.isSameLocalModel(local, 'categoria')) {
            this.locals.categorias = local.localizacao;
        }
    }
}
ImageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ImageService_Factory() { return new ImageService(i0.ɵɵinject(i1.HttpClient)); }, token: ImageService, providedIn: "root" });
