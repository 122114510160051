/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./criar-conta-modal.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../partials/cliente-simulador-de-frete/cliente-simulador-de-frete.component.ngfactory";
import * as i3 from "../solicitacao-ceps/shared/solicitacao-cep.service";
import * as i4 from "@angular/common/http";
import * as i5 from "../ceps/shared/cep.service";
import * as i6 from "angular-2-local-storage";
import * as i7 from "../tipo-entregas/shared/tipo-entrega.service";
import * as i8 from "../textos/shared/texto.service";
import * as i9 from "../shared/services/correio.service";
import * as i10 from "../partials/cliente-simulador-de-frete/cliente-simulador-de-frete.component";
import * as i11 from "../filiais/shared/filial.service";
import * as i12 from "@angular/router";
import * as i13 from "../clientes/shared/cliente.service";
import * as i14 from "ngx-bootstrap/modal";
import * as i15 from "./criar-conta-modal.component";
var styles_CriarContaModalComponent = [i0.styles];
var RenderType_CriarContaModalComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CriarContaModalComponent, data: {} });
export { RenderType_CriarContaModalComponent as RenderType_CriarContaModalComponent };
export function View_CriarContaModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "criar-conta-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-close criar-conta-modal__close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeButton() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "header", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Quero me cadastrar"])), (_l()(), i1.ɵeld(5, 0, null, null, 10, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Antes de se cadastrar, verifique se seu endere\u00E7o faz parte da nossa \u00E1rea de cobertura de servi\u00E7o "])), (_l()(), i1.ɵeld(8, 0, null, null, 7, "app-cliente-simulador-de-frete", [], null, [[null, "aoCadastrar"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("aoCadastrar" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ClienteSimuladorDeFreteComponent_0, i2.RenderType_ClienteSimuladorDeFreteComponent)), i1.ɵprd(4608, null, i3.SolicitacaoCepService, i3.SolicitacaoCepService, [i4.HttpClient]), i1.ɵprd(512, null, i5.CepService, i5.CepService, [i4.HttpClient, i6.LocalStorageService]), i1.ɵprd(512, null, i7.TipoEntregaService, i7.TipoEntregaService, [i4.HttpClient]), i1.ɵprd(512, null, i8.TextoService, i8.TextoService, [i4.HttpClient]), i1.ɵprd(512, null, i9.CorreioService, i9.CorreioService, [i4.HttpClient]), i1.ɵdid(14, 114688, null, 0, i10.ClienteSimuladorDeFreteComponent, [i6.LocalStorageService, i5.CepService, i7.TipoEntregaService, i11.FilialService, i12.Router, i13.ClienteService, i8.TextoService, i14.BsModalService, i9.CorreioService], null, { aoCadastrar: "aoCadastrar" }), (_l()(), i1.ɵted(-1, null, ["Simulador de Taxa de servi\u00E7o..."]))], function (_ck, _v) { _ck(_v, 14, 0); }, null); }
export function View_CriarContaModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_CriarContaModalComponent_0, RenderType_CriarContaModalComponent)), i1.ɵdid(1, 49152, null, 0, i15.CriarContaModalComponent, [i14.BsModalService], null, null)], null, null); }
var CriarContaModalComponentNgFactory = i1.ɵccf("ng-component", i15.CriarContaModalComponent, View_CriarContaModalComponent_Host_0, {}, {}, []);
export { CriarContaModalComponentNgFactory as CriarContaModalComponentNgFactory };
