import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  private locals: {
    base: string;
    produtos: string;
    placeholders: string;
    categorias: string;
  } = {
    base: '',
    produtos: '',
    placeholders: '',
    categorias: '',
  };

  private loaded = false;

  constructor(private httpClient: HttpClient) {
    this.getLocals();
  }

  public getLocals() {
    if (this.loaded) {
      return Promise.resolve();
    } else {
      return this.httpClient
        .get(environment.apiUrl + '/loja/arquivos/localizacao')
        .toPromise()
        .then((res: any) => {
          const locals = res.data || [];
          this.loaded = true;
          if (locals.length > 0) {
            locals.forEach((local) => {
              this.setProducts(local);
              this.setBase(local);
              this.setPlaceholders(local);
              this.setCategorias(local);
            });
          }
        });
    }
  }

  public getBasePath() {
    return `${this.locals.base}/files/`;
  }

  public getPlaceholders() {
    return this.locals.placeholders;
  }

  public getPathCategoria(imagem) {
    return `${this.locals.categorias}/${imagem}`;
  }

  public getPathPlaceholderBySize(size) {
    return `${this.locals.placeholders}/${size}.png`;
  }

  public getPathProductImageBySize(imagem, size) {
    return `${this.locals.produtos}/${size}x${size}/${imagem}`;
  }

  private isSameLocalModel(local, model: string) {
    return local.model.toLowerCase() === model;
  }

  private setProducts(local) {
    if (this.isSameLocalModel(local, 'produto')) {
      this.locals.produtos = local.localizacao;
    }
  }

  private setBase(local) {
    if (this.isSameLocalModel(local, 's3')) {
      this.locals.base = local.localizacao;
    }
  }

  private setPlaceholders(local) {
    if (this.isSameLocalModel(local, 'placeholder')) {
      this.locals.placeholders = local.localizacao;
    }
  }

  private setCategorias(local) {
    if (this.isSameLocalModel(local, 'categoria')) {
      this.locals.categorias = local.localizacao;
    }
  }
}
