import { Component, Input, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { take } from 'rxjs/operators';
import { CentroDistribuicaoService } from 'src/app/centro-distribuicao/shared/centro-distribuicao.service';
import { FilialService } from 'src/app/filiais/shared/filial.service';
import { PrevisaoEntregaComponent } from 'src/app/tipo-entregas/previsao-entrega-modal/previsao-entrega.component';
import { TipoEntregaService } from 'src/app/tipo-entregas/shared/tipo-entrega.service';
import { ProdutoAvisoService } from '../../../produtos/produto-aviso/shared/produto-aviso.service';
import { ProdutoAviso } from '../../../produtos/produto-aviso/shared/produto_avisos';
import { Oferta } from '../../../produtos/shared/ofertas';
import { CarrinhoAnonimoService } from '../shared/carrinho-anonimo.service';
import { CarrinhoService } from '../shared/carrinho.service';
import { TipoProdutoCarrinho } from '../shared/tipo-produto-carrinho';

@Component({
  selector: 'app-carrinho-adicionar',
  templateUrl: './carrinho-adicionar.component.html',
  styleUrls: ['./carrinho-adicionar.component.css'],
  providers: [ProdutoAvisoService, CookieService],
})
export class CarrinhoAdicionarComponent implements OnInit {
  @Input() produtoId: number;
  @Input() disponivel: boolean = false;
  @Input() tipoProduto: TipoProdutoCarrinho = 'produto';

  @Input() quantidade: number = 1;
  @Input() quantidadeMaxima: number;

  @Input() emOferta: boolean = false;
  @Input() tipoOferta: number = 0;
  @Input() quantidadeMaximaOferta: number = 0;

  @Input() public appMtOrigin: string;

  public produtoAviso: ProdutoAviso = {} as ProdutoAviso;
  public oferta: Oferta = {} as Oferta;
  public online: boolean = false;
  public quantidadeMaximaPermitida: number;
  showLoading: boolean = false;

  constructor(
    private carrinhoService: CarrinhoService,
    private produtoAvisoService: ProdutoAvisoService,
    private cdService: CentroDistribuicaoService,
    private filialService: FilialService,
    private modalService: BsModalService,
    private tipoEntregaService: TipoEntregaService,
    private carrinhoAnonimoService: CarrinhoAnonimoService,
  ) {}

  public ngOnInit() {
    this.quantidadeMaximaPermitida = this.calculaQuantidadeMaximaPermitida();
  }

  public solicitarProduto() {
    const salvar$ = this.produtoAvisoService
      .salvar(this.cdService.id, this.produtoId)
      .subscribe((res) => {
        if (res.success === true) {
          alert('Solicitação enviada com sucesso.');
        } else if (res.error) {
          alert(res.error);
        }
        salvar$.unsubscribe();
      });
  }

  showAvailableWarn() {
    return this.carrinhoService.logged;
  }

  getActionLabel() {
    let label = 'Carrinho Adicionar';
    if (this.appMtOrigin) {
      label = `Carrinho Adicionar > ${this.appMtOrigin}`;
    }
    return label;
  }

  comprar() {
    if (this.errorQtMax()) {
      this.quantidade = this.quantidadeMaximaPermitida;
      this.carrinhoService.showMsgErrorQtMax();
    }

    if (this.filialService.alertaFilialSemEcommerce()) {
      return;
    }

    if (
      (
        !this.carrinhoService.possuiCarrinho &&
        !this.cdService.loadingModalSelectCd &&
        this.modalService.getModalsCount() === 0
      ) ||
      (
        !this.carrinhoService.possuiCarrinho &&
        !this.cdService.loadingModalSelectCd &&
        !this.carrinhoAnonimoService.permitirCarrinhoAnonimo
      )
    ) {
      this.cdService.loadingModalSelectCd = true;
      const modalRef = this.cdService.abrirSelecionarLoja();
      modalRef.content.onClose.subscribe(() => {
        this.cdService.loadingModalSelectCd = false;
        this.validateLoading();
        this.additem();
      });
    } else {
      this.validateLoading();
      this.additem();
    }
  }

  additem() {
    this.carrinhoService.addItem(
      this.cdService.id,
      this.produtoId,
      this.quantidade,
      this.tipoProduto
    );
  }

  private validateLoading() {
    this.showLoading = true;
    this.carrinhoService.afterAddCarrinho$.pipe(take(1)).subscribe(() => {
      this.showLoading = false;
      this.exibirAlertaPrevisaoEntrega();
    });
  }

  public exibirAlertaPrevisaoEntrega() {
    if (this.tipoEntregaService.disponibilidade.exibirAlerta) {
      let modalRef = this.modalService.show(PrevisaoEntregaComponent, {
        keyboard: true,
        class: 'custom-modal w400',
        ignoreBackdropClick: false,
      });
      this.tipoEntregaService.disponibilidade.exibirAlerta = false;
    }
  }

  private calculaQuantidadeMaximaPermitida() {
    return this.carrinhoService.calcQtMaxAdd(
      this.quantidadeMaxima,
      this.emOferta,
      this.tipoOferta,
      this.quantidadeMaximaOferta
    );
  }

  private errorQtMax() {
    if (!this.quantidadeMaximaPermitida) {
      return false;
    }

    return Number(this.quantidade) > this.quantidadeMaximaPermitida;
  }
}
