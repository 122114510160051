import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TipoEntrega } from '../../tipo-entregas/shared/tipo_entregas';
import { CepService } from '../../ceps/shared/cep.service';
import { SolicitacaoCepService } from '../../solicitacao-ceps/shared/solicitacao-cep.service';
import { TipoEntregaService } from '../../tipo-entregas/shared/tipo-entrega.service';
import { SolicitacaoCep } from '../../solicitacao-ceps/shared/solicitacao_ceps';
import { FilialService } from '../../filiais/shared/filial.service';
import { LocalStorageService } from 'angular-2-local-storage';
import { ClienteService } from 'src/app/clientes/shared/cliente.service';
import { TextoService } from '../../textos/shared/texto.service';
import { BsModalService } from 'ngx-bootstrap';
import { ModalCentroDistribuicaoListComponent } from 'src/app/shared/modal-centro-distribuicao-list/modal-centro-distribuicao-list.component';
import { CorreioService } from 'src/app/shared/services/correio.service';
@Component({
  selector: 'app-cliente-simulador-de-frete',
  templateUrl: './cliente-simulador-de-frete.component.html',
  styleUrls: ['./cliente-simulador-de-frete.component.css'],
  providers: [
    CepService,
    SolicitacaoCepService,
    TipoEntregaService,
    TextoService,
    CorreioService
  ],
})
export class ClienteSimuladorDeFreteComponent implements OnInit {
  public cep: string = '';
  public email_cep: string;
  public cadastro: boolean = false;
  public cadastro_erro: boolean = false;
  public emValidacao: boolean = false;
  public tipoEntregas: TipoEntrega[] = [];
  public resultado: number = 0;
  public bloquearNovosClientes = false;
  public solicitacao: SolicitacaoCep = <SolicitacaoCep>{};
  public solicitacaoCep;
  public televendas: any;

  public cepValido: boolean = true;
  public possuiRetiradas: boolean = false;
  public loading: boolean = false;

  public exibirErroCepNaoEncontrado: boolean = false;
  public msgErroCepNaoEncontrado: string = '';
  public permiteCadastro: boolean = false;

  @Input() retorno: number = 0;
  @Output() aoCadastrar = new EventEmitter<any>();

  private tipoRetorno = {
    CADASTRO: 0,
    SIMULADOR_FRETE: 1,
  };
  public readonly QUANTIDADE_CDS_EXIBICAO = 3;

  constructor(
    private localStorageService: LocalStorageService,
    private cepService: CepService,
    private tipoEntregaService: TipoEntregaService,
    private filialService: FilialService,
    private router: Router,
    private clienteService: ClienteService,
    private textoService: TextoService,
    private modalService: BsModalService,
    private correioService: CorreioService
  ) {
    this.televendas = this.localStorageService.get('operador');
  }

  public ngOnInit() {
    setTimeout(() => {
      this.bloquearNovosClientes =
        this.filialService.detail.bloquear_novos_cadastros_clientes;
    }, 2000);
  }

  public validarCep(cep) {
    this.cep = cep;
    this.cadastro = false;
    this.cadastro_erro = false;
    this.tipoEntregas = [];
    this.resultado = 0;
    this.cepValido = true;
    this.exibirErroCepNaoEncontrado = false;

    if (cep.length === 8 && !this.emValidacao) {
      this.loading = true;

      this.clienteService.permitirCadastro(this.cep).subscribe((res: any) => {
        const { data: permiteCadastro } = res;

        if(permiteCadastro) {
          this.correioService.consultarCEP(cep).then(() => {
            const existeCep = this.correioService.success;

            if(existeCep) {
              this.cepService.validar(this.cep).subscribe((data: any) => {
                if (data.success) {
                  if (this.retorno == this.tipoRetorno.CADASTRO) {
                    this.abrirCadastro();
                  } else {
                    this.buscarTipoEntregas().then(() => {});
                  }
                } else {
                  this.exibirAvisoSomenteRetirada();
                }
                this.loading = false;
              });
            } else {
              this.cepInexistente()
            }
          });
        }else {
          this.naoPermitirCadastro()
        }
      });
    }

    this.emValidacao = cep.length !== 8;
  }

  private naoPermitirCadastro() {
    this.loading = false;
    this.cepValido = false;
    this.possuiRetiradas = false;
  }

  private cepInexistente() {
    this.msgErroCepNaoEncontrado = this.correioService.errorMessage;
    this.exibirErroCepNaoEncontrado = true;
    this.permiteCadastro = false;
    this.loading = false;
  }

  private exibirAvisoSomenteRetirada() {
    this.cepValido = false;
    this.possuiRetiradas = true;
    this.textoService.setSlug('aviso-de-somente-retirada');
  }

  private buscarTipoEntregas() {
    return this.tipoEntregaService
      .calcularFreteTodosCds(this.cep, 0)
      .then(() => {
        this.tipoEntregas = this.tipoEntregaService.tipoEntregas;
        this.resultado = 1;
      });
  }

  private abrirCadastro() {
    this.router.navigate(['/clientes/cadastro/' + this.cep]);
    this.aoCadastrar.next();
  }

  public validarCadastro() {
    if (
      this.retorno === this.tipoRetorno.CADASTRO &&
      (this.cepValido || this.possuiRetiradas)
    ) {
      this.abrirCadastro();
    } else if (this.retorno === this.tipoRetorno.SIMULADOR_FRETE) {
      this.validarCep(this.cep);
    }
  }

  public limparResultados() {
    this.tipoEntregas = [];
    this.resultado = 0;
    this.emValidacao = false;
  }

  public modal(cds) {

    const modal = this.modalService.show(ModalCentroDistribuicaoListComponent);

    modal.content.cds = cds.split("|");
  }

  public checkCentroDistribuicao(cds) {
    return cds ? cds.split("|").length : 0;
  }

  public showCentroDistribuicao(cds){
    if(this.checkCentroDistribuicao(cds) <= this.QUANTIDADE_CDS_EXIBICAO) {
      return cds
    } else {
       return cds.split("|").slice(0, this.QUANTIDADE_CDS_EXIBICAO).join("|") + ' ...'
    }
  }
}
