import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Departamento } from '../../produtos/produto-departamento/shared/departamentos';
import { Colecao } from '../../shared/interfaces/colecoes';
import { Receita } from '../../receitas/shared/receitas';
import { Lista } from '../../receitas/listas/shared/listas';
import { DepartamentoService } from '../../shared/services/departamento.service';
import { ColecaoService } from '../../shared/services/colecao.service';
import { ReceitaService } from '../../receitas/shared/receita.service';
import { ListaService } from '../../receitas/listas/shared/lista.service';
import { CombosService } from '../../shared/services/combos.service';

import * as $ from 'jquery';
import { trigger, transition, style, animate } from '@angular/animations';
import { Subscription } from 'rxjs';
import { ClienteService } from 'src/app/clientes/shared/cliente.service';
import { CentroDistribuicaoService } from 'src/app/centro-distribuicao/shared/centro-distribuicao.service';
import { ImageService } from 'src/app/shared/services/imagem.service';

declare let Swiper: any;

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class TabsComponent implements OnInit, OnDestroy {
  @Input() public total: number = 0;

  public departamentos: Departamento[] = [];
  public favoritos: Array<{
    departamento_id: number;
    quantidade: number;
  }> = [];
  public colecoes: Colecao[] = [];
  public receitas: Receita[] = [];
  public listas: Lista[] = [];
  public combos: any[] = [];
  public baseSrc: string = '';
  public link: string = '';
  public mouse: any;
  public currentSwiper: any = {};
  public height: string = '0px';
  public isFirefox = navigator.userAgent.indexOf('Firefox') > -1;

  private atualizaDepartamentos$: Subscription;
  private alterarLoja$: Subscription;
  private atualizaFavoritos$: Subscription;
  private afterLogin$: Subscription;
  private afterLogout$: Subscription;
  public showMenu: boolean = true;

  constructor(
    private departamentoService: DepartamentoService,
    private colecaoService: ColecaoService,
    private receitaService: ReceitaService,
    private listaService: ListaService,
    private combosService: CombosService,
    private clienteService: ClienteService,
    private cdService: CentroDistribuicaoService,
    private imageService: ImageService
  ) {
    imageService.getLocals().then(() => {
      this.baseSrc = imageService.getBasePath();
    });
  }

  public atualizarColecoes = () => {
    this.colecoes = this.colecaoService.colecoes;
  };
  public atualizarReceitas = () => {
    this.receitas = this.receitaService.receitas;
  };

  public atualizarListas = () => {
    this.listas = this.listaService.listas;
  };

  private formataInfoCombos(combos) {
    return combos.map(item => {
      const dp = this.departamentoService.findDepartamentoById(
        item.departamento_id
      );
      return {
        departamento_id: item.departamento_id,
        descricao: dp.descricao,
        imagem: dp.imagem,
        link: dp.link,
        quantidade: item.quantidade
      };
    });
  }

  private buscarCombos() {
    const combos$ = this.combosService
      .buscarCombos(this.cdService.id)
      .subscribe(res => {
        combos$.unsubscribe();
        if (!res.data || res.data.length === 0) {
          return (this.combos = []);
        }

        this.combos = this.formataInfoCombos(res.data);
      });
  }

  private atualizaDepartamentos() {
    this.atualizaDepartamentos$ = this.departamentoService.atualizaDepartamentos$.subscribe(
      (departamentos: any) => {
        this.departamentos = departamentos;
        this.swiper('departamentos');
        this.buscarCombos();
        this.buscaFavoritos();
      }
    );
  }

  private buscaDepartamentos() {
    this.departamentoService.listagem(
      this.cdService.id
    );
  }

  private formataFavoritos(favoritos) {
    return favoritos.map(item => {
      const dp = this.departamentoService.findDepartamentoById(
        item.departamento_id
      );
      return {
        descricao: dp.descricao,
        quantidade: item.quantidade,
        link: dp.link,
        imagem: dp.imagem
      };
    });
  }

  private buscaFavoritos() {
    if (this.clienteService.logged()) {
      this.departamentoService.buscaFavoritos(this.cdService.id);
    }
  }

  private inicializaFavoritos() {
    this.afterLogin$ = this.clienteService.afterLogin$.subscribe(() => {
      this.buscaFavoritos();
    });

    this.afterLogout$ = this.clienteService.afterLogout$.subscribe(() => {
      this.departamentoService.limparFavoritos();
    });

    this.atualizaFavoritos$ = this.departamentoService.atualizaFavoritos$.subscribe(
      (favoritos: any) => {
        this.favoritos = this.formataFavoritos(favoritos);
      }
    );
  }

  private alterarLoja() {
    // TODO: Alterar para o app component
    this.alterarLoja$ = this.cdService.selecionarLoja$.subscribe(info => {
      if (info.lojaAlterada) {
        this.buscaDepartamentos();
      }
    });
  }

  public ngOnInit() {
    this.buscaDepartamentos();
    this.atualizaDepartamentos();
    this.inicializaFavoritos();
    this.alterarLoja();

    this.departamentoService.showMenu$.subscribe((value) => this.showMenu = value);

    window.addEventListener('atualizarColecoes', this.atualizarColecoes);
    window.addEventListener('atualizarReceitas', this.atualizarReceitas);
    window.addEventListener('atualizarListas', this.atualizarListas);
  }

  public ngOnDestroy() {
    this.atualizaDepartamentos$.unsubscribe();
    this.atualizaFavoritos$.unsubscribe();
    this.afterLogin$.unsubscribe();
    this.afterLogout$.unsubscribe();
    this.alterarLoja$.unsubscribe();

    window.removeEventListener('atualizarColecoes', this.atualizarColecoes);
    window.removeEventListener('atualizarReceitas', this.atualizarReceitas);
    window.removeEventListener('atualizarListas', this.atualizarListas);
  }

  public swiper(area: string) {
    for (const key in this.currentSwiper) {
      if (this.currentSwiper.hasOwnProperty(key)) {
        this.currentSwiper[key] = false;
      }
    }
    this.currentSwiper[area] = true;

    setTimeout(() => {
      const mySwiper = new Swiper('#' + area, {
        // Optional parameters
        loop: false,
        slidesPerView: 'auto',
        paginationClickable: true,
        spaceBetween: 0,
        setWrapperSize: true,
        // If we need pagination
        // pagination: '.swiper-pagination',
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev'
      });

      $('.swiper-button-prev, .swiper-button-next').show();
    }, 2000);
  }

  public haOfertas() {
    return (
      this.departamentos.filter(
        (departamento: Departamento) => departamento.total_ofertas > 0
      ).length > 0
    );
  }

  public haFavoritos() {
    return this.favoritos.length > 0;
  }

  public haColecoes() {
    return this.colecoes.length > 0;
  }

  public haReceitas() {
    return this.receitas.length > 0;
  }

  public haListas() {
    return this.listas.length > 0;
  }

  public haCombos() {
    return this.combos.length > 0;
  }

  public over(departamento: any) {
    if (!departamento.link) {
      this.mouse = setTimeout(() => {
        this.link = null;
      }, 200);
    } else {
      clearTimeout(this.mouse);
      if (departamento.link !== this.link) {
        this.link = departamento.link;
        if (this.isFirefox) {
          this.calcHeightForMozilla(departamento.children.length);
        }
      }
    }
  }

  private calcHeightForMozilla(length: any) {
    // Calcula a quantidade de linhas completas do menu, que preencham as três colunas
    let size = parseInt((length / 3).toString(), 0);
    // Verifica se sobrou algum item e adiciona uma nova linha
    let rest = length % 3 > 0 ? 1 : 0;
    this.height = size * 33 + (rest % 3) * 33 + 'px';
  }

  public getUrl(imagem: string, area: string) {
    return this.imageService.getPathCategoria(imagem);
  }

  public getFallback() {
    return this.imageService.getPathPlaceholderBySize(60);
  }
}
