import { CashbackExtrato } from './../shared/CashbackExtrato';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { MinhaContaCashbackService } from '../shared/minha-conta-cashback.service';
import { fork } from 'cluster';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-minha-conta-cashback-modal-extrato',
  templateUrl: './minha-conta-cashback-modal-extrato.component.html',
  styleUrls: ['./minha-conta-cashback-modal-extrato.component.css'],
})
export class MinhaContaCashbackModalExtratoComponent implements OnInit {
  public saldo: number;
  public extrato = [];
  public loading: boolean;

  constructor(
    private cashbackService: MinhaContaCashbackService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.carregarDadosCashback();
  }

  carregarDadosCashback() {
    Promise.all([this.carregarSaldo(), this.carregarExtrato()]);
  }

  async carregarSaldo() {
    this.loading = true;
    this.cashbackService.saldo().subscribe((cashbackSaldoResponse) => {
      const {
        saldo: { saldo },
      } = cashbackSaldoResponse;
      this.saldo = saldo;
      this.loading = false;
    });
  }

  async carregarExtrato() {
    this.cashbackService.extrato().subscribe((cashbackExtratoResponse) => {
      this.loading = true;
      const { extrato = [] } = cashbackExtratoResponse;
      this.extrato = extrato.slice(0, 6);
      this.loading = false;
    });
  }

  fecharModal() {
    this.modalService._hideModal(1);
  }

  getTipoOperacaoCredito(tipo: number) {
    return tipo % 2 === 1;
  }
}
