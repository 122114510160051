<div class="modal-header">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="fecharModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title text-info">Cashback</h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-xs-12 col-md-12">
      <div class="text-center checkout--loading" *ngIf="loading">
        <span><i class="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i></span>
      </div>
      <div class="row" *ngIf="saldo>=0">
        <div class="col-lg-12">
          <span>Saldo disponível: </span>
          <strong>{{ saldo | price }}</strong>
        </div>
      </div>
      <div class="clearfix">&nbsp;</div>
      <dl>
        <dd *ngFor="let mov of extrato">
          <app-minha-conta-cashback-tipo-operacao [tipoOperacao]="mov.tipo_transacao" [label]="false">
          </app-minha-conta-cashback-tipo-operacao>
          <div style="display: inline-grid">
            <span style="line-height: 20px">{{
              mov.dat_transacao | date: 'dd/MM/yyyy'
              }}</span>
            <span style="line-height: 10px">
              <span *ngIf="getTipoOperacaoCredito(mov.tipo_transacao)">
                Recebido de volta
                <strong class="text-success">{{
                  mov.vlr_extrato | price
                  }}</strong>
              </span>
              <span *ngIf="!getTipoOperacaoCredito(mov.tipo_transacao)">
                Utilizado
                <strong class="text-danger">{{
                  mov.vlr_extrato | price
                  }}</strong>
              </span>
            </span>
          </div>
        </dd>
      </dl>

    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger pull-right" id="closeModalButton" (click)="fecharModal()">
    Fechar
  </button>
</div>
