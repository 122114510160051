<div *ngIf="disponivel">
  <div class="col-xs-5 col-sm-4 col-md-4 nopadding">
    <app-carrinho-spinner
      [produtoId]="produtoId"
      [(quantidade)]="quantidade"
      [salvarAposAlteracao]="false"
      [tipoProduto]="tipoProduto"
      [quantidadeMaxima]="quantidadeMaximaPermitida"
      [quantidadeMinima]="1"
    ></app-carrinho-spinner>
  </div>
  <div class="col-xs-7 col-sm-8 col-md-8 nopadding">
    <div>
      <button
        class="btn btn-purchase pull-left"
        (click)="comprar()"
        id="btn-{{ produtoId }}"
        type="button"
        appMetrics
        [attr.disabled]="showLoading && showAvailableWarn()? 'disabled' : null"
        [appMtActionClick]="getActionLabel()"
      >
        <i class="fa fa-shopping-cart hidden-xs" aria-hidden="true"></i> COMPRAR
      </button>
    </div>

    <div
      style="clear: both;font-size: 12px"
      class="pull-left feedback"
      id="info-{{ produtoId }}"
      *ngIf="showLoading"
    >
      <span class="text-danger" *ngIf="quantidade <= 0"
        >Produto removido...</span
      >

      <span class="text-success" *ngIf="quantidade > 0 && showAvailableWarn()"
        >Produto adicionado...</span
      >
    </div>
  </div>
</div>

<div class="row" *ngIf="!disponivel">
  <div class="col-xs-12 col-sm-12 col-md-12 em-falta">
    <div>Produto indisponível</div>
    <a
      href="javascript:void(0);"
      (click)="solicitarProduto()"
      *ngIf="showAvailableWarn()"
      appMetrics
      [appMtActionClick]="getActionLabel()"
      >Avise-me quando chegar</a
    >
  </div>
</div>
