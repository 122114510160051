import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { MinhaContaCashbackService } from '../shared/minha-conta-cashback.service';
export class MinhaContaCashbackModalExtratoComponent {
    constructor(cashbackService, modalService) {
        this.cashbackService = cashbackService;
        this.modalService = modalService;
        this.extrato = [];
    }
    ngOnInit() {
        this.carregarDadosCashback();
    }
    carregarDadosCashback() {
        Promise.all([this.carregarSaldo(), this.carregarExtrato()]);
    }
    carregarSaldo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            this.cashbackService.saldo().subscribe((cashbackSaldoResponse) => {
                const { saldo: { saldo }, } = cashbackSaldoResponse;
                this.saldo = saldo;
                this.loading = false;
            });
        });
    }
    carregarExtrato() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.cashbackService.extrato().subscribe((cashbackExtratoResponse) => {
                this.loading = true;
                const { extrato = [] } = cashbackExtratoResponse;
                this.extrato = extrato.slice(0, 6);
                this.loading = false;
            });
        });
    }
    fecharModal() {
        this.modalService._hideModal(1);
    }
    getTipoOperacaoCredito(tipo) {
        return tipo % 2 === 1;
    }
}
