import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import 'gasparesganga-jquery-loading-overlay/dist/loadingoverlay';
import { ImageService } from 'src/app/shared/services/imagem.service';
import { Produto } from '../shared/produto';

@Component({
  selector: 'app-produto-imagem',
  templateUrl: './produto-imagem.component.html',
  styleUrls: ['./produto-imagem.component.css']
})
export class ProdutoImagemComponent implements OnInit, OnChanges {
  public srcImagem: string;
  public offset: number = 450;
  public imgLoaded: boolean = false;

  @Input() public produto: Produto = <Produto>{};
  @Input() public tamanho: number = 60;
  @Input() public classe: string = '';
  @Input() public preventLink: boolean = false;

  constructor(private imageService: ImageService) {}

  ngOnInit() {
    this.offset = window.innerWidth < 768 ? window.innerHeight : 450;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('produto' in changes) {
      setTimeout(() => {
        this.srcImagem = this.getUrl(changes.produto.currentValue.imagem, this.tamanho);
        this.validateShowImage();
      }, 100);
    }
  }

  private validateShowImage() {
    if (!this.srcImagem) {
      this.setImageLoaded();
    }
  }

  public getUrl(imagem: string, tamanho: number) {
    return this.imageService.getPathProductImageBySize(imagem, tamanho);
  }

  public getFallback() {
    const tamanho =
      this.tamanho <= 60 ? 60 : this.tamanho > 500 ? 500 : this.tamanho;

    return this.imageService.getPathPlaceholderBySize(tamanho);
  }

  public getRouterLink() {
    if (this.preventLink) {
      return null;
    }

    return `/produtos/detalhe/${this.produto.produto_id}/${this.produto.link}`;
  }

  public onLoadImage(loaded) {
    if (this.findBiggestImage(loaded)) {
      this.srcImagem = this.getUrl(this.produto.imagem, 500);
    } else {
      this.setImageLoaded();
    }
  }

  private findBiggestImage(loaded) {
    return !loaded && this.srcImagem && this.srcImagem.indexOf('500') === -1
  }

  private setImageLoaded() {
    this.imgLoaded = true;
  }
}
