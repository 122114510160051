<ng-template #templateProdutoComDesconto let-oferta>
  <div class="info-text">
    <span>Por apenas</span>
  </div>
  <div class="info-price">
    {{ oferta.preco_oferta | price
    }}<span class="info-price"> {{unidade}} </span>
  </div>
  <div class="{{ arrowClass }}">
    <div class="bullet"></div>
  </div>
</ng-template>

<ng-template #templateMaisPorMenos let-oferta>
  <div class="info-text">
    <span>A partir de {{ oferta.quantidade_minima }} por</span>
  </div>
  <div class="info-price">
    {{ oferta.preco_oferta | price
    }}<span class="info-price"> {{unidade}} </span>
  </div>
  <div class="{{ arrowClass }}">
    <div class="bullet"></div>
  </div>
</ng-template>

<ng-container *ngIf="produto.disponivel">
  <ng-container *ngIf="!produto.em_oferta">
    <div class="drill-price drill-price-md text-danger">
      <div class="info-text">
        <span>Por apenas</span>
      </div>
      <div class="info-price">
        {{ produto.preco | price }}
        <span class="info-price"> {{unidade}} </span>
      </div>
      <div class="{{ arrowClass }}">
        <div class="bullet"></div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="produto.em_oferta">
    <div class="drill-price drill-price-sm text-danger" *ngIf="produto.oferta.classe_oferta">
      <div class="info-price" *ngIf="mostrarComparacaoPrecoAntigo(produto.oferta)">
        {{ produto.oferta.preco_antigo | price }} <span *ngIf="!produto.possui_unidade_diferente">{{ unidade }} </span>
      </div>
      <div class="info-price" *ngIf="mostrarComparacaoPrecoOferta(produto.oferta)">
        {{ produto.oferta.preco_oferta | price }} <span *ngIf="!produto.possui_unidade_diferente">{{ unidade }} </span>
      </div>
      <div class="{{ arrowClass }}">
        <div class="bullet"></div>
      </div>
    </div>

    <div class="drill-price drill-price-md text-success" [ngSwitch]="produto.oferta.classe_oferta">
      <ng-template ngSwitchDefault>
        <ng-container *ngIf="!produto.oferta.classe_oferta || produto.oferta.quantidade_minima == 1">
          <ng-container *ngTemplateOutlet="templateProdutoComDesconto; context: { $implicit: produto.oferta }"></ng-container>
        </ng-container>
        <ng-container *ngIf="produto.oferta.classe_oferta && produto.oferta.quantidade_minima > 1">
          <ng-container *ngTemplateOutlet="templateMaisPorMenos; context: { $implicit: produto.oferta }"></ng-container>
        </ng-container>
      </ng-template>
      <ng-template [ngSwitchCase]="'oferta-exclusiva'">
        <ng-container *ngIf="!produto.oferta.classe_oferta || produto.oferta.quantidade_minima == 1">
          <ng-container *ngTemplateOutlet="templateProdutoComDesconto; context: { $implicit: produto.oferta }"></ng-container>
        </ng-container>
        <ng-container *ngIf="produto.oferta.classe_oferta && produto.oferta.quantidade_minima > 1">
          <ng-container *ngTemplateOutlet="templateMaisPorMenos; context: { $implicit: produto.oferta }"></ng-container>
        </ng-container>
      </ng-template>
      <ng-template [ngSwitchCase]="'mais-por-menos'">
        <div class="info-text">
          A partir de {{ produto.oferta.quantidade_minima }} por
        </div>
        <div class="info-price">
          {{ produto.oferta.preco_oferta | price
          }}<span class="info-price"> {{unidade}} </span>
        </div>
        <div class="{{ arrowClass }}">
          <div class="bullet"></div>
        </div>
      </ng-template>
      <ng-template [ngSwitchCase]="'leve-e-pague'">
        <div class="info-text">
          Leve {{ produto.oferta.quantidade_minima }} pague
          {{ produto.oferta.quantidade_pagar }}
        </div>
        <div class="info-price">
          {{
            (produto.oferta.preco_oferta * produto.oferta.quantidade_pagar) /
              produto.oferta.quantidade_minima | price
          }}<span class="info-price"> {{unidade}} </span>
        </div>
        <div class="{{ arrowClass }}">
          <div class="bullet"></div>
        </div>
      </ng-template>
      <ng-template [ngSwitchCase]="'combo-vip'">
        <div class="info-text">No combo</div>
        <div class="info-price">
          {{ produto.oferta.preco_oferta | price
          }}<span class="info-price"> {{unidade}} </span>
        </div>
        <div class="{{ arrowClass }}">
          <div class="bullet"></div>
        </div>
      </ng-template>
      <ng-template [ngSwitchCase]="'oferta-atacarejo'">
        <div [ngSwitch]="produto.oferta.faixas_precos.length">
          <ng-template [ngSwitchCase]="1">
            <div class="info-text">A cada {{produto.oferta.faixas_precos[0].quantidade}} por</div>
            <div class="info-price oferta-atacarejo" *ngFor="let faixa of produto.oferta.faixas_precos">{{faixa.valor | price}} {{unidade}} </div>
          </ng-template>
          <ng-template [ngSwitchCase]="2">
            <div class="info-price oferta-atacarejo" style="font-size: 14px; line-height: 18px" *ngFor="let faixa of produto.oferta.faixas_precos">{{faixa.quantidade}} por {{faixa.valor | price}}
              {{unidade}} </div>
          </ng-template>
          <ng-template ngSwitchDefault>
            <div class="info-price oferta-atacarejo" style="font-size: 12px" *ngFor="let faixa of produto.oferta.faixas_precos">{{faixa.quantidade}} por {{faixa.valor | price}} {{unidade}} </div>
          </ng-template>
        </div>
        <div class="{{ arrowClass }}">
          <div class="bullet"></div>
        </div>
      </ng-template>
    </div>
  </ng-container>
</ng-container>
