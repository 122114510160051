import { Injectable } from '@angular/core';
import { TipoEntrega } from './tipo_entregas';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Disponibilidade } from './disponibilidade';

@Injectable()
export class TipoEntregaService {
  constructor(private httpClient: HttpClient) {}

  public service: string = '/tipo-entregas';
  public tipoEntregas: TipoEntrega[] = [];
  public tipoEntrega: TipoEntrega = <TipoEntrega>{};
  public disponibilidade: Disponibilidade = <Disponibilidade>{};

  private requests: any = {};

  index() {
    return this.httpClient
      .get(this.service)
      .toPromise()
      .then((data: any) => (this.tipoEntregas = data.tipoEntregas));
  }

  calcularFrete(cdId, enderecoID, qtdItens, tipoEntregaID?) {
    const tpEntrega = !!tipoEntregaID ? `/tipo_entrega/${tipoEntregaID}` : '';
    const url = `${environment.apiUrl}/loja/compras/checkout/calcular_frete/filial/${environment.filialId}/centro_distribuicao/${cdId}/endereco/${enderecoID}/total_itens/${qtdItens}${tpEntrega}`;

    if (this.checkExistsRequest(url)) {
      return this.requests[url];
    }

    const request = this.buscaCalcularFrete(url);

    this.saveRequest(url, request);
    return request;
  }

  private buscaCalcularFrete(url) {
    return this.httpClient
      .get(url)
      .toPromise()
      .then((result: any) => (this.tipoEntregas = result.data));
  }

  private saveRequest(url: string, request: any) {
    this.requests[url] = request;
  }

  private checkExistsRequest(url) {
    return this.requests && this.requests[url];
  }

  calcularFreteTodosCds(cep: string, qtdItens: number) {
    return this.httpClient
      .get(
        `${environment.apiUrl}/loja/tipo_entregas/calcular_frete_cep/filial/${environment.filialId}/cep/${cep}`
      )
      .toPromise()
      .then((result: any) => (this.tipoEntregas = result.data));
  }

  setEntrega(tipoEntrega: TipoEntrega) {
    this.tipoEntrega = tipoEntrega;
  }

  getEntrega() {
    return Promise.resolve(this.tipoEntrega);
  }

  getTipoEntregas() {
    return this.tipoEntregas;
  }

  filtraRetirada(retiradas) {
    return retiradas.filter((item) => {
      return Number(item.retirada_loja) === 1;
    });
  }

  filtraTipoEntrega(retiradas) {
    return retiradas.filter((item) => {
      return Number(item.retirada_loja) === 0;
    });
  }
  getDisponibilidade(cdId) {
    return this.httpClient
      .get(`${this.service}/disponibilidade/${cdId}`)
      .toPromise()
      .then((data: Disponibilidade) => {
        this.disponibilidade = data;
      });
  }
}
