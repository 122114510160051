import { SharedModule } from '../shared/shared.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BannersComponent } from './banners/banners.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PaginacaoComponent } from './paginacao/paginacao.component';
import { TabsComponent } from './tabs/tabs.component';
import { TabsBarComponent } from './tabs-bar/tabs-bar.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CarrinhoComponent } from './carrinho/carrinho.component';
import { CarrinhoSpinnerComponent } from './carrinho/carrinho-spinner/carrinho-spinner.component';
import { CarrinhoAdicionarComponent } from './carrinho/carrinho-adicionar/carrinho-adicionar.component';
import { BuscaRapidaComponent } from './busca-rapida/busca-rapida.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { OfflineComponent } from './offline/offline.component';
import { CardAdicionarCarrinhoComponent } from './card-adicionar-carrinho/card-adicionar-carrinho.component';
import { NguCarouselModule } from '@ngu/carousel';
import { CriarListaProdutosComponent } from './minhas-listas/criar-lista-produtos/criar-lista-produtos.component';
import { PopoverModule } from 'ngx-bootstrap';
import { TagPrecoComponent } from './tag-preco/tag-preco.component';
import { TagPrecoOriginal } from './tag-preco-original/tag-preco-original.component';
import { TagPromotionComponent } from './tag-promotion/tag-promotion.component';
import { ProdutoCardComponent } from './produto-card/produto-card.component';
import { ProdutosIndisponiveisListaComponent } from './produtos-indisponiveis-lista/produtos-indisponiveis-lista.component';
import { TermosUsoComponent } from './termos-uso/termos-uso.component';
import { ListaPaginasLinkComponent } from './lista-paginas-link/lista-paginas-link.component';
import { AceiteCookieComponent } from './aceite-cookie/aceite-cookie.component';
import { MinhaContaCashbackTipoOperacaoComponent } from '../minha-conta/minha-conta-cashback/minha-conta-cashback-tipo-operacao/minha-conta-cashback-tipo-operacao.component';

@NgModule({
  declarations: [
    BannersComponent,
    CarrinhoComponent,
    CarrinhoSpinnerComponent,
    CarrinhoAdicionarComponent,
    FooterComponent,
    HeaderComponent,
    BuscaRapidaComponent,
    NavbarComponent,
    PaginacaoComponent,
    TabsComponent,
    TabsBarComponent,
    CardAdicionarCarrinhoComponent,
    NotFoundComponent,
    OfflineComponent,
    CriarListaProdutosComponent,
    TagPrecoComponent,
    TagPrecoOriginal,
    TagPromotionComponent,
    ProdutoCardComponent,
    ProdutosIndisponiveisListaComponent,
    TermosUsoComponent,
    ListaPaginasLinkComponent,
    AceiteCookieComponent,
    MinhaContaCashbackTipoOperacaoComponent,
  ],
  exports: [
    BannersComponent,
    CarrinhoComponent,
    CarrinhoSpinnerComponent,
    CarrinhoAdicionarComponent,
    FooterComponent,
    HeaderComponent,
    BuscaRapidaComponent,
    NavbarComponent,
    PaginacaoComponent,
    TabsComponent,
    TabsBarComponent,
    CardAdicionarCarrinhoComponent,
    NotFoundComponent,
    OfflineComponent,
    CriarListaProdutosComponent,
    TagPrecoComponent,
    TagPrecoOriginal,
    TagPromotionComponent,
    ProdutoCardComponent,
    ProdutosIndisponiveisListaComponent,
    TermosUsoComponent,
    ListaPaginasLinkComponent,
    MinhaContaCashbackTipoOperacaoComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
    LazyLoadImageModule,
    NguCarouselModule,
    PopoverModule.forRoot(),
    ReactiveFormsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PartialsModule {}
