import { Component, OnInit, Input } from '@angular/core';
import { Produto } from 'src/app/produtos/shared/produto';

@Component({
  selector: 'app-tag-preco',
  templateUrl: './tag-preco.component.html',
  styleUrls: ['./tag-preco.component.less']
})
export class TagPrecoComponent implements OnInit {

  @Input() produto: Produto = {} as Produto;
  @Input() arrowDirection: 'left'|'right' = 'right';

  get arrowClass() {
    return this.arrowDirection ? `arrow-${this.arrowDirection}` : 'arrow-right';
  }

  constructor() { }

  ngOnInit() { }

  get unidade() {
    return !this.produto.possui_unidade_diferente 
      ? "un." : "";
  }

  mostrarComparacaoPrecoOferta(oferta) {
    if (!oferta) {
      return false;
    }
    return oferta.classe_oferta === 'leve-e-pague' || oferta.classe_oferta === 'oferta-atacarejo';
  }

  mostrarComparacaoPrecoAntigo(oferta) {
    const excetoOfertas = !this.mostrarComparacaoPrecoOferta(oferta);
    return excetoOfertas;
  }
}
