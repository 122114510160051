import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { timer } from 'rxjs';
import { CentroDistribuicaoService } from 'src/app/centro-distribuicao/shared/centro-distribuicao.service';
import { CarrinhoService } from '../shared/carrinho.service';
import { TipoProdutoCarrinho } from '../shared/tipo-produto-carrinho';

@Component({
    selector: 'app-carrinho-spinner',
    templateUrl: './carrinho-spinner.component.html',
    styleUrls: ['./carrinho-spinner.component.css']
})
export class CarrinhoSpinnerComponent implements OnInit {
    @Input() carrinhoId: number;
    @Input() itemId: number;
    @Input() produtoId: number;
    @Input() quantidade: number = 1;
    @Input() tipoProduto: TipoProdutoCarrinho;
    @Input() salvarAposAlteracao: boolean = true;
    @Output() quantidadeChange = new EventEmitter();

    @Input() callback: number = 0;
    @Input() class: number = 0;
    @Input() preco_venda: number;
    @Input() total_preco: number;
    @Input() quantidadeMaxima: number;
    @Input() quantidadeMinima: number = 0;

    private ultimaQuantidade: number = 1;
    private updateQtTimer;

    constructor(
        private carrinhoService: CarrinhoService,
        private cdService: CentroDistribuicaoService
    ) {}

    ngOnInit(): void {
        this.ultimaQuantidade = this.quantidade;
    }

    public updateQtSpinner() {

        let timerMs: number = (this.salvarAposAlteracao) ? 500 : 100;

        if (this.errorQtMax()) {
            this.quantidade = this.quantidadeMaxima;
            this.carrinhoService.showMsgErrorQtMax();
        }

        if (this.updateQtTimer) {
            this.updateQtTimer.unsubscribe();
        }

        this.updateQtTimer = timer(timerMs).subscribe(() => this.atualizar());
    }

    mais() {
        this.ultimaQuantidade = this.quantidade;
        this.quantidade = parseInt(this.quantidade.toString(), 10) + 1;
        this.updateQtSpinner();
    }

    menos() {
        this.ultimaQuantidade = this.quantidade;
        this.quantidade = this.quantidade - 1;

        if (this.quantidade >= this.quantidadeMinima) {
            this.updateQtSpinner();
        } else {
            this.quantidade = this.quantidadeMinima;
        }
    }

    atual() {
        this.quantidadeChange.emit(this.quantidade);
        if (this.callback == 1) {
            this.updateQtSpinner();
        }
    }

    private validaRemoverItem(): boolean {
        return this.quantidade <= 0 && !!this.carrinhoId && !!this.itemId;
    }

    private confirmarExclusao() {
        return this.carrinhoService.remover();
    }

    private cancelaExlusao() {
        this.quantidade = this.ultimaQuantidade;
        this.quantidadeChange.emit(this.ultimaQuantidade);
    }

    atualizar() {
        if (this.validaRemoverItem()) {
            if (this.confirmarExclusao()) {
                return this.carrinhoService.apagarItem(this.cdService.id, this.itemId);
            }
            return this.cancelaExlusao();
        }

        if (this.errorQtMax()) {
            this.quantidade = this.quantidadeMaxima;
            this.carrinhoService.showMsgErrorQtMax();
        }

        if (this.salvarAposAlteracao) {
            this.salvarAposAlteracao = false;
            return this.carrinhoService.addItem(
                this.cdService.id,
                this.produtoId,
                this.quantidade,
                this.tipoProduto
            );
        }

        this.quantidadeChange.emit(this.quantidade);
    }

    private errorQtMax() {
        if (!this.quantidadeMaxima) {
            return false;
        }

        return Number(this.quantidade) > Number(this.quantidadeMaxima);
    }

    comprar() {
        this.salvarAposAlteracao = true;
        this.updateQtSpinner();
    }
}
