import { Injectable } from '@angular/core';
import { Pagina } from './paginas';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class InstitucionalService {
  public service: string = '/paginas';
  public data: any;
  public categorias: any;
  public pagina: Pagina = <Pagina>{};
  public paginas: Pagina[] = [];

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  sanitizeContent(content) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  index() {
    if (this.paginas.length > 0) {
      return Promise.resolve(this.paginas);
    } else {
      return this.httpClient
        .get(this.service + '/index/' + environment.filialId)
        .toPromise()
        .then((data: any) => {
          this.paginas = data.paginas;
          window.dispatchEvent(new Event('atualizarPaginas'));
          return data.paginas;
        });
    }
  }

  view(slug: string) {
    if (this.paginas.length > 0) {
      let pagina: Pagina;
      this.paginas.forEach((pag: any) => {
        if (pag.slug == slug) {
          pagina = pag;
        }
      });
      this.pagina = Object.assign({}, pagina);
      if (this.pagina.slug != 'simulador-de-frete') {
        this.pagina.conteudo = this.sanitizeContent(this.pagina.conteudo);
      }

      return Promise.resolve(pagina);
    } else {
      return this.httpClient
        .get(this.service + '/view/' + environment.filialId + '/' + slug)
        .toPromise()
        .then((data: any) => {
          this.pagina = data.pagina;
          this.pagina.conteudo = this.sanitizeContent(this.pagina.conteudo);
          return data.pagina;
        });
    }
  }

  perguntas() {
    return this.httpClient
      .get(
        `${environment.apiUrl}/loja/categoria_perguntas/filial/${environment.filialId}`
      )
      .toPromise()
      .then((res: any) => {
        this.categorias = res.data;
        return res.data;
      });
  }
}
