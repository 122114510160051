<div class="row">
  <div class="col-md-12">
    <div class="alert alert-warning" role="alert" *ngIf="bloquearNovosClientes">
      <strong>Atenção:</strong><br />
      No momento, não estamos realizando novos cadastros.
      <br />
      Por favor tente posteriormente.
    </div>

    <form *ngIf="!bloquearNovosClientes">
      <app-ceps (cepChange)="validarCep($event)"></app-ceps>

      <div class="text-center loading-cep" *ngIf="loading">
        <span><i class="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i></span>
      </div>

      <div *ngIf="!loading && !cepValido && possuiRetiradas">
        <app-textos>Texto </app-textos>
      </div>

      <div *ngIf="!loading && !cepValido && !possuiRetiradas">
        <app-solicitacao-ceps [cep]="cep"></app-solicitacao-ceps>
      </div>

      <ng-container *ngIf="exibirErroCepNaoEncontrado">
        <div class="text-center text-danger"> {{msgErroCepNaoEncontrado}}</div>
      </ng-container>

      <button
        type="button"
        [disabled]="!(cep && cep.length == 8 && !loading && !exibirErroCepNaoEncontrado)"
        (click)="validarCadastro()"
        class="btn btn-success pull-right"
        *ngIf="cepValido || possuiRetiradas"
      >
        <i class="fa fa-check" aria-hidden="true"></i> <span>Enviar</span>
      </button>
    </form>

    <div class="clearfix">&nbsp;</div>

    <table
      class="table table-bordered gray mt20"
      *ngIf="!loading && retorno == 1 && resultado == 1"
    >
      <thead>
        <tr>
          <th>Loja</th>
          <th>Tipo de entrega</th>
          <th>Limite de itens</th>
          <th>Taxa de serviço</th>
        </tr>
      </thead>
      <tbody *ngFor="let item of tipoEntregas">
        <tr *ngIf="televendas != null || item.exclusivo_televendas === false">
          <td>
            {{ item.centro_distribuicao_nome ? showCentroDistribuicao(item.centro_distribuicao_nome) : "--"}}
            <a class="cursor-pointer" *ngIf="checkCentroDistribuicao(item.centro_distribuicao_nome) > QUANTIDADE_CDS_EXIBICAO" (click)="modal(item.centro_distribuicao_nome)">
              <i class="fa fa-external-link" aria-hidden="true"></i>
            </a>
          </td>
          <td>{{ item.descricao }}</td>
          <td>
            {{
              item.quantidade_maxima_itens ? item.quantidade_maxima_itens : '--'
            }}
          </td>
          <td>{{ item.frete | price }}</td>
        </tr>
      </tbody>
    </table>

    <button
      *ngIf="retorno == 1 && resultado == 1"
      class="btn btn-danger"
      (click)="limparResultados()"
    >
      <i
        class="fa fa-ban text-danger"
        style="color: #ffffff"
        aria-hidden="true"
      ></i>
      Limpar resultados
    </button>
  </div>
</div>
