import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AplicativoService } from 'src/app/shared/services/aplicativo.service';
import { Filial } from '../../filiais/shared/filiais';
import { FilialService } from '../../filiais/shared/filial.service';
import { InstitucionalService } from '../../institucional/shared/institucional.service';
import { Pagina } from '../../institucional/shared/paginas';
import { CentroDistribuicaoService } from 'src/app/centro-distribuicao/shared/centro-distribuicao.service';
import { FooterService } from 'src/app/shared/services/footer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public paginas: Pagina[] = [];
  public filial: Filial = <Filial>{};
  public texto_quem_somos = 'quem somos';
  public texto_forma_pagamentos = 'forma de pagamentos';
  public url_google: any;
  public url_facebook_fanpage: any;
  public link_ebit: any;
  public selo_ssl: any;
  public appleID: string;
  public package: string;
  public showFooter: boolean = true;
  public script: boolean = false;

  constructor(
    private filialService: FilialService,
    private paginaService: InstitucionalService,
    private sanitizer: DomSanitizer,
    private aplicativoService: AplicativoService,
    private cdService: CentroDistribuicaoService,
    private footerService: FooterService
  ) { }

  public ngOnInit() {
    this.getScripts();
    this.buscaPaginas();

    this.filial = this.filialService.detail;
    this.setGoogleSafe();
    this.setFacebookFanpage();
    this.setInfoEbit();
    this.setSeloSsl();
    this.buscaInfoAplicativo();
    this.footerService.showFooter$.subscribe((value) => this.showFooter = value);
  }

  private setInfoEbit() {
    if (this.filial.link_ebit) {
      this.link_ebit = this.sanitizer.bypassSecurityTrustUrl(
        this.filial.link_ebit
      );
    }
  }

  private setSeloSsl() {
    if (this.filial.selo_ssl) {
      this.selo_ssl = this.sanitizer.bypassSecurityTrustHtml(
        this.filial.selo_ssl
      );
    }
  }

  private setFacebookFanpage() {
    if (this.filial.url_facebook_fanpage) {
      this.url_facebook_fanpage = this.filial.url_facebook_fanpage;
    }
  }

  private setGoogleSafe() {
    this.url_google = this.sanitizer.bypassSecurityTrustUrl(
      "javascript:window.open('http://www.google.com/safebrowsing/diagnostic?site=" +
      this.filial.site_url +
      "', '', 'width=960px, height=600px')"
    );
  }

  private buscaPaginas() {
    this.paginaService.index().then(() => {
      this.paginas = this.paginaService.paginas;
      this.texto_quem_somos = this.getPagina('quem-somos');
      this.texto_forma_pagamentos = this.getPagina('formas-de-pagamento');
    });
  }

  public getPagina(slug: string) {
    let texto = '';
    this.paginas.forEach((pag: any) => {
      if (pag.slug.indexOf(slug) > -1) {
        texto = pag.descricao;
      }
    });
    return texto;
  }

  public getSlug(slug: string) {
    let texto = '';
    this.paginas.forEach((pag: any) => {
      if (pag.slug.indexOf(slug) > -1) {
        texto = pag.slug;
      }
    });
    return texto;
  }

  public goToUrl(url: string) {
    window.open(url, '_blank', 'noopener');
  }

  private urlLinkWhatsApp(relacionamento: any): string {

    let haveLink = relacionamento.link_whatsapp || false;
    let whatsappNumber = relacionamento.whatsapp || this.filial.telefone_whatsapp;
    if ((!haveLink) || (!whatsappNumber)) {
      return '';
    }

    let message = (relacionamento.mensagem_whatsapp) ? "&text=" + relacionamento.mensagem_whatsapp : '';
    return "https://api.whatsapp.com/send?phone=55" + whatsappNumber.replace(/[^0-9]/g, '').toString() + message;
  }

  public get relacionamentoCliente() {
    const relacionamento: any = this.cdService.cdSelecionado.relacionamento_cliente || {};
    return {
      email: relacionamento.email || this.filial.email_sav,
      telefone: relacionamento.telefone || this.filial.telefone_sav,
      whatsapp: relacionamento.whatsapp || this.filial.telefone_whatsapp,
      link_whatsapp: parseInt(relacionamento.link_whatsapp) || 0,
      url_whatsapp: this.urlLinkWhatsApp(relacionamento)
    }
  }

  private buscaInfoAplicativo() {
    this.aplicativoService.getInfoAplicativo().subscribe((res: any) => {
      if (res.data) {
        this.appleID = res.data.apple_id;
        this.package = res.data.package;
      }
    });
  }

  private getScripts() {
    this.filialService.buscarScripts().subscribe(req => {
      const scripts = req.data;
      if (scripts.length > 0) {
        this.script = true;
      }
    });
  }
 
}
