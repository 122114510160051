<div id="sugestoes" tabindex="-1" [hidden]="hidden">
  <div class="clearfix" id="sugestoes-barra">
    <div class="col-xs-3 col-sm-3">
      <span class="title">sugestões de pesquisa</span>
    </div>
    <div class="col-xs-9 col-sm-9"><span class="title">produtos</span></div>
  </div>
  <div class="clearfix" id="sugestoes-resultado">
    <div class="col-xs-3 col-sm-3">
      <ul class="row selector">
        <li *ngFor="let sugestao of (sugestoes$ | async)?.data">
          <a href="javascript:void(0);" (click)="close()" [routerLink]="['/produtos/buscas']"
            [queryParams]="{ q: sugestao.termo }">{{ sugestao.termo }}</a>
        </li>
      </ul>
    </div>
    <ng-container *ngIf="(busca$ | async); let busca">
      <div class="col-xs-9 col-sm-9">
        <div class="row" *ngIf="(busca?.data && busca.data?.produtos); else notFoundProduct">
          <div class="produto clearfix" *ngFor="let produto of busca.data.produtos">
            <div class="border">
              <div class="col-sm-2 imagem-container">
                <app-produto-imagem [produto]="produto" [tamanho]="60" [preventLink]="true"></app-produto-imagem>
              </div>
              <div class="col-sm-5 descricao">
                <a href="javascript:void(0);" routerLink="/produtos/detalhe/{{ produto.produto_id }}/{{
                    produto.link
                  }}" title="{{ produto.descricao }}" (click)="close()">{{ produto.descricao }}</a>
              </div>
              <div class="col-sm-2 preco">
                {{ getPrecoProduto(produto) | price }}
              </div>
              <div class="col-sm-3 comprar">
                <app-carrinho-adicionar class="clearfix" [produtoId]="produto.produto_id"
                  [disponivel]="produto.disponivel" appMtOrigin="Busca rápida" [tipoOferta]="produto?.oferta?.tipo_oferta_id"
                  [quantidadeMaxima]="produto.quantidade_maxima" [emOferta]="produto.em_oferta"
                  [quantidadeMaximaOferta]="produto?.oferta?.quantidade_maxima"
                  [tipoProduto]="produto.em_oferta ? produto.oferta.classe_oferta : 'produto'">Botao...
                </app-carrinho-adicionar>
              </div>
            </div>
          </div>
        </div>
        <ng-template #notFoundProduct>
          <div class="row">
            <div class="produto clearfix">
              <div class="border">
                <div class="col-sm-12">
                  Nenhum produto encontrado
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </div>
  <div id="sugestoes-footer">
    <a [routerLink]="['/produtos/buscas']" [queryParams]="{ q: query }" (click)="close()">Ver todos os resultados</a>
  </div>
</div>
