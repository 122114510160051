import { Injectable } from '@angular/core';
import { Endereco } from './enderecos';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { States } from 'src/app/enderecos/shared/states';
import { LocalStorageService } from 'angular-2-local-storage';

@Injectable()
export class EnderecoService {
  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService
  ){}

  public service: string = `${environment.apiUrl}/loja/clientes/enderecos`;
  public enderecos: Endereco[] = [];
  public endereco: Endereco = <Endereco>{};

  private states: Array<{ name: string; initials: string }> = States;

  index() {
    return this.httpClient
      .get(this.service)
      .toPromise()
      .then((res: any) => (this.enderecos = res.data));
  }

  view(id: number) {
    return this.httpClient
      .get(`${this.service}/${id}`)
      .toPromise()
      .then((res: any) => (this.endereco = res.data));
  }

  principal() {
    return this.httpClient.get(`${this.service}/principal`);
  }

  add(data: Endereco) {
    return this.httpClient
      .post(`${this.service}/filial/${environment.filialId}/adicionar`, data)
      .pipe(
        map((res: any) => {
          if ('message' in res) {
            alert(res.message);
          }
          return res;
        })
      );
  }

  edit(data: Endereco) {
    return this.httpClient.post(`${this.service}/${data.id}`, data).pipe(
      map((res: any) => {
        if ('message' in res) {
          alert(res.message);
        }
        this.setCepEnderecoPrincipalInLocalStorage(data.cep);
        return res;
      })
    );
  }

  deleteItem(id: number) {
    return this.httpClient.delete(`${this.service}/${id}`).pipe(
      map((res: any) => {
        if ('message' in res) {
          alert(res.message);
        }
        return res;
      }),
      catchError(error => {
        alert(error.error.error);
        return error;
      })
    );
  }

  getSiglaUf(descricaoUf: string) {
    const estado = this.states.find((state) => {
      return state.name === descricaoUf;
    });
    return estado ? estado.initials : null;
  }

  private setCepEnderecoPrincipalInLocalStorage(endereco_principal_cep) {
    const cliente = this.localStorageService.get('cliente');
    Object.assign(cliente, { endereco_principal_cep });
    this.localStorageService.set("cliente", cliente)
  }
}
