/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./minha-conta-cashback-tipo-operacao.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./minha-conta-cashback-tipo-operacao.component";
var styles_MinhaContaCashbackTipoOperacaoComponent = [i0.styles];
var RenderType_MinhaContaCashbackTipoOperacaoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MinhaContaCashbackTipoOperacaoComponent, data: {} });
export { RenderType_MinhaContaCashbackTipoOperacaoComponent as RenderType_MinhaContaCashbackTipoOperacaoComponent };
function View_MinhaContaCashbackTipoOperacaoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sem informa\u00E7\u00F5es"]))], null, null); }
function View_MinhaContaCashbackTipoOperacaoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-info-circle"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MinhaContaCashbackTipoOperacaoComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 2, 0, currVal_0); }, null); }
function View_MinhaContaCashbackTipoOperacaoComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cr\u00E9dito"]))], null, null); }
function View_MinhaContaCashbackTipoOperacaoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-arrow-circle-down text-success"], ["title", "Cr\u00E9dito"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MinhaContaCashbackTipoOperacaoComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 2, 0, currVal_0); }, null); }
function View_MinhaContaCashbackTipoOperacaoComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["D\u00E9bito"]))], null, null); }
function View_MinhaContaCashbackTipoOperacaoComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-arrow-circle-up text-danger"], ["title", "D\u00E9bito"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MinhaContaCashbackTipoOperacaoComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 2, 0, currVal_0); }, null); }
function View_MinhaContaCashbackTipoOperacaoComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Compra Cancelada"]))], null, null); }
function View_MinhaContaCashbackTipoOperacaoComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-plus-circle text-success"], ["title", "Compra Cancelada"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MinhaContaCashbackTipoOperacaoComponent_8)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 2, 0, currVal_0); }, null); }
function View_MinhaContaCashbackTipoOperacaoComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Saldo Vencido"]))], null, null); }
function View_MinhaContaCashbackTipoOperacaoComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-minus-circle text-warning"], ["title", "Saldo Vencido"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MinhaContaCashbackTipoOperacaoComponent_10)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MinhaContaCashbackTipoOperacaoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["id", "cashback-tipo"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MinhaContaCashbackTipoOperacaoComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MinhaContaCashbackTipoOperacaoComponent_3)), i1.ɵdid(5, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MinhaContaCashbackTipoOperacaoComponent_5)), i1.ɵdid(7, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MinhaContaCashbackTipoOperacaoComponent_7)), i1.ɵdid(9, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MinhaContaCashbackTipoOperacaoComponent_9)), i1.ɵdid(11, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tipoOperacao; _ck(_v, 1, 0, currVal_0); var currVal_1 = 1; _ck(_v, 5, 0, currVal_1); var currVal_2 = 2; _ck(_v, 7, 0, currVal_2); var currVal_3 = 3; _ck(_v, 9, 0, currVal_3); var currVal_4 = 4; _ck(_v, 11, 0, currVal_4); }, null); }
export function View_MinhaContaCashbackTipoOperacaoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-minha-conta-cashback-tipo-operacao", [], null, null, null, View_MinhaContaCashbackTipoOperacaoComponent_0, RenderType_MinhaContaCashbackTipoOperacaoComponent)), i1.ɵdid(1, 49152, null, 0, i3.MinhaContaCashbackTipoOperacaoComponent, [], null, null)], null, null); }
var MinhaContaCashbackTipoOperacaoComponentNgFactory = i1.ɵccf("app-minha-conta-cashback-tipo-operacao", i3.MinhaContaCashbackTipoOperacaoComponent, View_MinhaContaCashbackTipoOperacaoComponent_Host_0, { tipoOperacao: "tipoOperacao", label: "label" }, {}, []);
export { MinhaContaCashbackTipoOperacaoComponentNgFactory as MinhaContaCashbackTipoOperacaoComponentNgFactory };
