import { Router } from '@angular/router';
import { ClienteService } from './../clientes/shared/cliente.service';
import { BsModalService } from 'ngx-bootstrap';
import { Component, ViewEncapsulation, ViewChild, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Environment } from './../../environments/model';
import { Subject } from 'rxjs';

@Component({
  styleUrls: ['./recuperar-senha-modal.component.css'],
  templateUrl: './recuperar-senha-modal.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class RecuperarSenhaModalComponent implements OnInit {
  public closeEvent$: Subject<any> = new Subject();

  public modalLevel = 1;
  public user: any = {};
  public loading = false;
  public env: Environment;

  @ViewChild('captchaRef', { static: true }) captchaRef;

  public ngOnInit() {
    this.env = environment;
  }

  public constructor(private modalService: BsModalService, private clienteService: ClienteService, private router: Router) { }

  public closeButton() {
    this.closeEvent$.next(true);
    this.close();
  }

  public close() {
    this.modalService._hideModal(this.modalLevel);
  }

  public validaSolicitacao() {
    if (!this.env.showCaptcha) {
      return;
    }

    this.captchaRef.reset();
    this.captchaRef.execute();
  }

  public recuperarSenha(captchaToken?: any) {
    const data = { contato: this.user.contato, documento: this.user.rec_documento, captchaToken };
    this.loading = true;
    this.clienteService
      .recuperarSenha(data)
      .toPromise()
      .then((response: any) => {
        this.loading = false;
        alert(response.message);
        if (response.success) {
          this.close();
          this.router.navigate(['/']);
        }
      });
  }
}
