import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { RecaptchaComponent, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { CepsComponent } from '../ceps/ceps.component';
import { ClienteSimuladorDeFreteComponent } from '../partials/cliente-simulador-de-frete/cliente-simulador-de-frete.component';
import { ProdutoImagemComponent } from '../produtos/produto-imagem/produto-imagem.component';
import { SolicitacaoCepsComponent } from '../solicitacao-ceps/solicitacao-ceps.component';
import { TextosComponent } from '../textos/textos.component';
import { CpfCnpjDirective } from './directives/cpf-cnpj.directive';
import { CpfDirective } from './directives/cpf.directive';
import { EmailTelefoneDirective } from './directives/email-telefone.directive';
import { ImgFallbackDirective } from './directives/img-fallback.directive';
import { MaskDirective } from './directives/mask.directive';
import { MetricsDirective } from './directives/metrics.directive';
import { MoneyDirective } from './directives/money.directive';
import { ModalCentroDistribuicaoListComponent } from './modal-centro-distribuicao-list/modal-centro-distribuicao-list.component';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { MatchPipe } from './pipes/match.pipe';
import { PricePipe } from './pipes/price.pipe';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { SafeResourceUrlPipe } from './pipes/safeResourceUrl.pipe';
import { FirebaseService } from './services/firebase.service';
import { SmartBannerService } from './services/smartbanner.service';
import { TermosUsoService } from './services/termos-uso.service';
import { ViewService } from './services/view.service';

@NgModule({
  declarations: [
    CapitalizePipe,
    MaskDirective,
    MoneyDirective,
    PricePipe,
    ImgFallbackDirective,
    ProdutoImagemComponent,
    TextosComponent,
    ClienteSimuladorDeFreteComponent,
    CepsComponent,
    SolicitacaoCepsComponent,
    MetricsDirective,
    CpfDirective,
    CpfCnpjDirective,
    MatchPipe,
    EmailTelefoneDirective,
    ModalCentroDistribuicaoListComponent,
    SafeResourceUrlPipe,
    SafeHtmlPipe
  ],
  imports: [RouterModule, FormsModule, CommonModule, LazyLoadImageModule, RecaptchaModule],
  exports: [
    CapitalizePipe,
    MaskDirective,
    MoneyDirective,
    PricePipe,
    ImgFallbackDirective,
    MetricsDirective,
    ProdutoImagemComponent,
    TextosComponent,
    ClienteSimuladorDeFreteComponent,
    CepsComponent,
    SolicitacaoCepsComponent,
    RecaptchaComponent,
    CpfDirective,
    CpfCnpjDirective,
    MatchPipe,
    EmailTelefoneDirective,
    ModalCentroDistribuicaoListComponent,
    SafeResourceUrlPipe,
    SafeHtmlPipe
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6Led2LUUAAAAAEp94Cec3sUMtDAMbvgkUXJxBKrg',
      } as RecaptchaSettings,
    },
    SmartBannerService,
    ViewService,
    FirebaseService,
    TermosUsoService,
  ],
  entryComponents: [
    ModalCentroDistribuicaoListComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
