import { Produto } from './../../../produtos/shared/produto';
import { MinhasListasService } from './../../../shared/services/minhas-listas.service';
import { BsModalService } from 'ngx-bootstrap';
import { MinhasListas } from './../../interfaces/minhas-listas';
import { Component, OnInit } from '@angular/core';
import { MinhasListasEditComponent } from '../minhas-listas-edit/minhas-listas-edit.component';

@Component({
  styleUrls: ['./minhas-listas-product.component.css'],
  templateUrl: './minhas-listas-product.component.html'
})
export class MinhasListasProductComponent implements OnInit {
  public list: MinhasListas = <MinhasListas>{};
  public lists: MinhasListas[] = [];
  public product: Produto = <Produto>{};
  public loading: boolean = false;
  public productMessage: string = '';

  public constructor(
    private modalService: BsModalService,
    private minhasListasService: MinhasListasService
  ) {}

  public ngOnInit() {
    this.start();
  }

  private start() {
    this.minhasListasService.getAll().then((response: any) => {
      this.lists = [{}, ...response.minhaListas];
      let filteredList = this.lists.filter(list => {
        return list && list.id === this.minhasListasService.selectedId;
      });
      if (filteredList.length) {
        this.list = filteredList[0];
        this.checkProduct();
      }
    });
  }

  public checkProduct() {
    this.productMessage = '';
    // if ('quantidade_por_produto' in this.list && this.list.quantidade_por_produto.hasOwnProperty(this.product.id)) {
    //     this.productMessage = 'Produto já existe na lista com quantidade ' + this.list.quantidade_por_produto[this.product.id];
    // }
  }

  public up() {
    this.product.quantidade += 1;
  }

  public down() {
    if (this.product.quantidade > 1) {
      this.product.quantidade -= 1;
    }
  }

  public edit() {
    if (this.product.quantidade < 1) {
      this.product.quantidade = 1;
    }
  }

  public save() {
    this.loading = true;
    this.minhasListasService
      .addProduct({
        minha_lista_id: this.list.id,
        produto_id: this.product.produto_id,
        quantidade: this.product.quantidade
      })
      .then((response: any) => {
        this.close();
        this.loading = false;
        this.minhasListasService.selectedId = this.list.id;
        alert(response.message);
      })
      .catch(error => {
        this.loading = false;
        if (error.message) {
          alert(error.message);
        }
      });
  }

  public close() {
    this.modalService._hideModal(1);
  }

  public showCreateModal(): any {
    let modalRef = this.modalService.show(MinhasListasEditComponent, {
      keyboard: true,
      class: 'custom-modal',
      ignoreBackdropClick: false
    });

    modalRef.content.title = 'Adicionar Lista';
    modalRef.content.new = true;
    modalRef.content.modalLevel = 2;
    modalRef.content.onHide = res => this.hideModal(res);
  }

  private hideModal(res) {
    if (res && res.minhaLista) {
      this.minhasListasService.selectedId = res.minhaLista.id;
      this.start();
    }
  }
}
