import { Injectable } from '@angular/core';
import { Filial } from './filiais';
import { FidelidadeService } from '../../shared/services/fidelidade.service';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Script } from './scripts';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class FilialService {

  // TODO: Remover afterLoadFilial$
  public afterLoadFilial$: Subject<any> = new Subject();
  public service: string = '/filiais';
  public scripts: Script[] = [];

  // TODO: Remover _filial
  private _filial: Filial = {} as Filial;
  public get detail(): Filial {
    if (!this._filial || !('id' in this._filial)) {
      const filial = atob(sessionStorage.getItem('vip-filial'));
      this._filial = JSON.parse(filial);
      this.fidelidadeService.setParams(this._filial.fidelidade);
    }

    return this._filial;
  }

  constructor(private httpClient: HttpClient, private titleService: Title, private fidelidadeService: FidelidadeService) {}

  public buscarScripts() {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/loja/filiais/${environment.filialId}/scripts`
    );
  }

  public view(id: number) {
    return this.httpClient
      .get(this.service + '/view/' + id)
      .toPromise()
      .then((data: any) => {
        this.scripts = 'scripts' in data && data.scripts ? data.scripts : [];
        this.setTitle(this.detail.titulo_site);
        this.afterLoadFilial$.next();
      });
  }

  public setTitle(title: string) {
    this.titleService.setTitle(title + ' - ' + this.detail.nome);
  }

  public alertaFilialSemEcommerce() {
    if (this.detail.filial_sem_ecommerce) {
      let texto = this.detail.texto_filial_sem_ecommerce || '';

      if (texto) {
        texto = `\n\n${texto}`;
      }

      alert(`Não disponível! ${texto}`);
    }

    return this.detail.filial_sem_ecommerce;
  }

  public getSlugOfertaExclusiva() {
    return this._filial
      .tag_oferta_exclusiva
      .replace(/\s/g, '-')
      .toLowerCase();
  }
}
