import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { ViewService } from 'src/app/shared/services/view.service';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "angular-2-local-storage";
import * as i3 from "./view.service";
export class StartupService {
    constructor(httpClient, lsService, viewService) {
        this.httpClient = httpClient;
        this.lsService = lsService;
        this.viewService = viewService;
    }
    possuiCdSelecionado() {
        return this.lsService.keys().indexOf('cdSelecionado') > -1;
    }
    getCdSelecionado() {
        return this.lsService.get('cdSelecionado');
    }
    setCdSelecionado(loja) {
        this.lsService.set('loja', loja);
        this.lsService.set('cdSelecionado', loja.id);
    }
    getFilialJson() {
        return this.httpClient
            .get(window.location.origin + '/assets/json/filial.json')
            .toPromise()
            .then((filialConfig) => {
            this.filialId = filialConfig.id;
            return filialConfig;
        });
    }
    buscaFilial(additionalData) {
        return this.getFilialJson()
            .then(() => {
            return this.httpClient
                .get(`${environment.apiUrl}/loja/filiais/${this.filialId}`)
                .toPromise();
        })
            .then((res) => {
            Object.assign(res.data, additionalData);
            this.salvaFilial(res.data);
            return res.data;
        });
    }
    buscaCd(cdId) {
        const CD_DETAIL_URL = `loja/centros_distribuicoes/${cdId}`;
        return this.httpClient
            .get(`${environment.apiUrl}/${CD_DETAIL_URL}`)
            .toPromise();
    }
    salvaFilial(filial) {
        const filialString = JSON.stringify(filial);
        sessionStorage.setItem('vip-filial', btoa(filialString));
    }
    salvarToken(token) {
        this.lsService.set('auth', token);
    }
    autenticacaoApi() {
        return this.httpClient
            .post(`${this.getApiUrl()}/auth/loja/login`, {
            domain: this.getHostname(),
            username: 'loja',
            key: 'df072f85df9bf7dd71b6811c34bdbaa4f219d98775b56cff9dfa5f8ca1bf8469'
        })
            .toPromise()
            .then((res) => {
            this.salvarToken(res.data);
            return res;
        });
    }
    getCdId(filial) {
        return this.possuiCdSelecionado()
            ? this.getCdSelecionado()
            : filial.centro_distribuicao_padrao_id;
    }
    getHostname() {
        let hostname = window.location.hostname;
        hostname = hostname.startsWith('www') ? hostname.replace('www.', '') : hostname;
        return hostname;
    }
    getWsUrl() {
        if (environment.production) {
            const hostname = this.getHostname();
            return `${window.location.protocol}//ws.${hostname}/loja`;
        }
        return environment.wsUrl;
    }
    getApiUrl() {
        if (environment.apiUrl) {
            return environment.apiUrl;
        }
        const hostname = this.getHostname();
        return `${window.location.protocol}//api.${hostname}/v1`;
    }
    getApiIntegracaoUrl() {
        if (environment.apiIntegracaoUrl) {
            return environment.apiIntegracaoUrl;
        }
        const hostname = this.getHostname();
        return `${window.location.protocol}//api-integracao.${hostname}`;
    }
    getMainDomain() {
        if ('main_domain' in environment) {
            return environment.main_domain;
        }
        return window.location.hostname.replace('www.', '');
    }
    setInfoEnvironment() {
        environment.apiUrl = this.getApiUrl();
        environment.wsUrl = this.getWsUrl();
        environment.apiIntegracaoUrl = this.getApiIntegracaoUrl();
        environment.main_domain = this.getMainDomain();
    }
    getOrganizacaoId() {
        if (!this.lsService.get('organizacao')) {
            this.httpClient
                .get(`${this.getApiUrl()}/organizacao`)
                .toPromise()
                .then((res) => {
                this.lsService.set('organizacao', res.data);
            });
        }
    }
    load() {
        this.setInfoEnvironment();
        this.getOrganizacaoId();
        return new Promise((resolve, reject) => {
            let fidelidade = {};
            this.autenticacaoApi()
                .then(() => {
                return this.buscaFilial({ fidelidade });
            })
                .then((filial) => {
                window.FILIAL = filial;
                environment.filialId = this.filialId;
                const cdId = this.getCdId(filial);
                return this.buscaCd(cdId);
            })
                .then((res) => {
                this.lsService.set('loja', res.data);
                return this.verificaParametrosURL().then(() => {
                    resolve(true);
                });
            })
                .catch(error => {
                reject(error);
            });
        });
    }
    logoutCliente() {
        return new Promise((resolve, reject) => {
            const token = this.lsService.get('token');
            if (token) {
                const headers = {
                    Authorization: `Basic ${token}`
                };
                return this.httpClient.post('/clientes/logout/', [], { headers }).toPromise().then(() => {
                    if (!!this.lsService.get('operador')) {
                        this.lsService.remove('token');
                        this.lsService.remove('cliente');
                    }
                    else {
                        this.lsService.clearAll('^((?!(loja|auth)).)*$');
                    }
                    resolve(true);
                }).catch(reject);
            }
            else {
                resolve(true);
            }
        });
    }
    getHeaders(token) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer ' + (token || this.getUserApiToken()));
        return headers;
    }
    getUserApiToken() {
        return this.lsService.get('tokenUsuarioApi');
    }
    setUserApiToken(token) {
        return this.lsService.set('tokenUsuarioApi', token);
    }
    loginClienteViaToken(token, cliente) {
        this.setUserApiToken(token);
        const data = {
            cliente,
            filial_id: environment.filialId
        };
        const options = {
            headers: this.getHeaders(),
        };
        return this.logoutCliente().then(() => {
            const URI = `user_api/cliente/logar`;
            return this.httpClient
                .post(`${environment.apiUrl}/loja/${URI}`, data, options)
                .toPromise()
                .then((resp) => {
                if (resp.success) {
                    this.afterLoginCliente(resp.data);
                    return true;
                }
                return false;
            }).catch(() => { });
        });
    }
    afterLoginCliente(token) {
        this.lsService.set('date', new Date().getTime());
        this.lsService.set('token', token);
    }
    getClienteBase64(urlParams) {
        try {
            return atob(urlParams.get('cliente'));
        }
        catch (_) {
            return false;
        }
    }
    verificaParametrosURL() {
        return new Promise((resolve, reject) => {
            const urlParams = new URLSearchParams(window.location.search);
            const inApp = urlParams.get('inApp') || false;
            this.viewService.setInAppMode(inApp);
            const token = urlParams.get('token');
            const cliente = this.getClienteBase64(urlParams);
            if (inApp && token && cliente) {
                this.loginClienteViaToken(token, cliente).then(() => {
                    resolve(true);
                }).catch(reject);
            }
            else if (inApp) {
                this.logoutCliente().then(() => {
                    resolve(true);
                }).catch(reject);
            }
            else {
                resolve(true);
            }
        });
    }
}
StartupService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StartupService_Factory() { return new StartupService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.ViewService)); }, token: StartupService, providedIn: "root" });
