import { CentroDistribuicaoComponent } from './centro-distribuicao/centro-distribuicao.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthGuard } from './shared/guards/auth.guard';
const ɵ0 = () => import("./produtos/produtos.module.ngfactory").then(m => m.ProdutosModuleNgFactory), ɵ1 = () => import("./produtos/produtos.module.ngfactory").then(m => m.ProdutosModuleNgFactory), ɵ2 = () => import("./compartilhar-lista/compartilhar-lista.module.ngfactory").then(m => m.CompartilharListaModuleNgFactory), ɵ3 = () => import("./minhas-listas/minhas-listas.module.ngfactory").then(m => m.MinhasListasModuleNgFactory), ɵ4 = () => import("./televendas/televendas.module.ngfactory").then(m => m.TelevendasModuleNgFactory), ɵ5 = () => import("./manutencao/manutencao.module.ngfactory").then(m => m.ManutencaoModuleNgFactory), ɵ6 = () => import("./sorteios/sorteios.module.ngfactory").then(m => m.SorteiosModuleNgFactory), ɵ7 = () => import("./produtos/produtos.module.ngfactory").then(m => m.ProdutosModuleNgFactory), ɵ8 = () => import("./receitas/receitas.module.ngfactory").then(m => m.ReceitasModuleNgFactory), ɵ9 = () => import("./institucional/institucional.module.ngfactory").then(m => m.InstitucionalModuleNgFactory), ɵ10 = () => import("./checkout/checkout.module.ngfactory").then(m => m.CheckoutModuleNgFactory), ɵ11 = () => import("./clientes/clientes.module.ngfactory").then(m => m.ClientesModuleNgFactory), ɵ12 = () => import("./minha-conta/minha-conta.module.ngfactory").then(m => m.MinhaContaModuleNgFactory), ɵ13 = () => import("./iframe/iframe.module.ngfactory").then(m => m.IframeModuleNgFactory);
export let routes = [
    // Home
    { path: '', loadChildren: ɵ0 },
    //HOME - Supermercado Online
    { matcher: matcherSupermercadoOnline, loadChildren: ɵ1 },
    // SHARED LIST
    {
        path: 'compartilhar-lista',
        loadChildren: ɵ2
    },
    // MY LISTS
    {
        path: 'minhas-listas',
        loadChildren: ɵ3,
        canActivate: [AuthGuard]
    },
    // TELEVENDAS
    {
        path: 'televendas',
        loadChildren: ɵ4
    },
    // MANUTENCAO
    {
        path: 'manutencao',
        loadChildren: ɵ5
    },
    // ROTAS DE COMPATIBILIDADE COM LOJA ANTIGA [REMOVER]
    { path: 'home', redirectTo: '/produtos/home' },
    {
        path: 'departamento/:departamento/:secao',
        redirectTo: 'produtos/departamento/:departamento/:secao',
        queryParamsHandling: 'preserve'
    },
    {
        path: 'departamento/:departamento',
        redirectTo: 'produtos/departamento/:departamento',
        queryParamsHandling: 'preserve'
    },
    { path: 'produto/:link', redirectTo: 'produtos/detalhe/:link' },
    {
        path: 'ofertas/:departamento',
        redirectTo: 'produtos/ofertas/departamento/:departamento',
        queryParamsHandling: 'preserve'
    },
    {
        path: 'meus-favoritos/:departamento',
        redirectTo: '',
        queryParamsHandling: 'preserve'
    },
    {
        path: 'combos/:departamento',
        redirectTo: 'Produtos/combos/:departamenteo',
        queryParamsHandling: 'preserve'
    },
    {
        path: 'mais-vendidos/:departamento',
        redirectTo: 'produtos/mais-vendidos/:departamento',
        queryParamsHandling: 'preserve'
    },
    {
        path: 'ofertas-por-tags/:tag',
        redirectTo: 'produtos/ofertas/tags/:tag',
        queryParamsHandling: 'preserve'
    },
    {
        path: 'buscas',
        redirectTo: 'produtos/buscas',
        queryParamsHandling: 'preserve'
    },
    {
        path: 'colecoes/:slug',
        redirectTo: 'produtos/colecoes/:slug',
        queryParamsHandling: 'preserve'
    },
    {
        path: 'listas/:slug',
        redirectTo: 'produtos/listas/:slug',
        queryParamsHandling: 'preserve'
    },
    {
        path: 'banners/:id',
        redirectTo: 'produtos/ofertas/banner/:id',
        queryParamsHandling: 'preserve'
    },
    {
        path: 'finalizar-carrinho',
        redirectTo: 'checkout/carrinho',
        queryParamsHandling: 'preserve'
    },
    {
        path: 'finalizar-entrega',
        redirectTo: 'checkout/entrega',
        queryParamsHandling: 'preserve'
    },
    {
        path: 'finalizar-pagamento',
        redirectTo: 'checkout/pagamento',
        queryParamsHandling: 'preserve'
    },
    {
        path: 'finalizar-pagamento-retorno/:id/:pg',
        redirectTo: 'checkout/retorno/:id/:pg',
        queryParamsHandling: 'preserve'
    },
    {
        path: 'finalizar-resumo/:id',
        redirectTo: 'checkout/resumo/:id',
        queryParamsHandling: 'preserve'
    },
    { path: 'sorteios', loadChildren: ɵ6 },
    // ROTAS DE COMPATIBILIDADE COM LOJA ANTIGA [REMOVER]
    { path: 'produtos', loadChildren: ɵ7 },
    { path: 'receitas', loadChildren: ɵ8 },
    {
        path: 'institucional',
        loadChildren: ɵ9
    },
    {
        path: 'checkout',
        loadChildren: ɵ10,
    },
    { path: 'clientes', loadChildren: ɵ11 },
    { path: 'i/:codigo', redirectTo: '/clientes/indicacao/:codigo' },
    {
        path: 'minha-conta',
        loadChildren: ɵ12,
        canActivate: [AuthGuard]
    },
    {
        path: 'integracao',
        loadChildren: ɵ13,
        canActivate: [AuthGuard]
    },
    { path: 'selecionar/centro_distribuicao/:id', component: CentroDistribuicaoComponent },
    { path: '404', component: NotFoundComponent },
    { path: '**', component: NotFoundComponent },
];
export function matcherSupermercadoOnline(url, group, route) {
    const regex = /\b(retirada|entrega)\/(cep|codigo_ibge)\/\d+\/(cd\/\d+\/)?$/g;
    const urlStr = url ? url.map(url => url.path).reduce((url, item) => (url + '/' + item), '') + '/' : '';
    if (!urlStr.match(regex))
        return null;
    const result = ({
        consumed: url, posParams: {
            "operacao": url[0],
            "tipo_busca": url[1],
            "codigo_busca": url[2],
        }
    });
    if (url[4])
        result.posParams["cd_id"] = url[4];
    return result;
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13 };
