import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {
  private errorTypes: any = {
    unauthorized: {
      code: 401,
      message: 'Sessão expirada'
    },
    notFound: {
      code: 404,
      message: 'Requisição não encontrada'
    },
    conflict: {
      code: 409,
      message: 'Versão inválida'
    },
    internalServerError: {
      code: 500,
      message: 'Ocorreu um erro inesperado'
    },
    default: {
      code: 0,
      message: 'Verifique sua conexão e tente novamente'
    }
  };

  private readonly carrinhoUrls = ['carrinhos', 'permitir_carrinho_anonimo'];

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router
  ) { }

  /**
   * Intercepts all request and updates the header
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   */
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let url = '';
    let headers = req.headers.set('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    const organizacaoId: string = this.localStorageService.get('organizacao');
    
    if (organizacaoId) {
      headers = headers.append('OrganizationID', organizacaoId);
    }

    if (!this.isStartUp(req.url)) {
      const possuiAuthorization = headers.has('Authorization');
      // TODO: remover auth
      const auth: string = this.localStorageService.get('auth');
      let token: string = this.localStorageService.get('token');
      const sessaoId = this.localStorageService.get('sessao');

      token = token || auth;

      if (token && !possuiAuthorization) {
        headers = headers.append('Authorization', 'Bearer ' + token);
      }

      if (new RegExp(this.carrinhoUrls.join('|')).exec(req.url)) {
        headers = headers.append('sessao-id', `${sessaoId}`);
      }

      if (this.isIncompleteUrl(req.url)) {
        headers = headers.append('FilialID', this.getFilialID());
        headers = headers.append('DomainKey', environment.main_domain);
        url += `${this.getWsUrl()}${req.url}`;
      } else {
        url = req.url;
      }
    }

    req = req.clone({
      url,
      headers
    });

    return next.handle(req).pipe(
      tap(
        (response: any) => {
          return response;
        },
        error => {
          if (error instanceof HttpErrorResponse) {
            if (this.isVorticeRequest(req.url)) {
                this.handleVorticeErrors(error);
            } else {
              if (
                error.status === this.errorTypes.unauthorized.code ||
                error.status === 403
              ) {
                this.localStorageService.remove('token');
                this.localStorageService.remove('cliente');
                this.localStorageService.remove('pedido');
                window.dispatchEvent(new Event('atualizarCliente'));
                setTimeout(() => {
                  window.dispatchEvent(new Event('atualizarCarrinhoCliente'));
                }, 100);
              } else if (error.status === this.errorTypes.notFound.code) {
                this.router.navigate(['/404']);
              }
            }
          }
          return throwError(error);
        }
      )
    );
  }

  private isStartUp(url) {
    return url.endsWith('configuracao/filiais/build');
  }

  /**
   * Check it is a url from vortice API
   * @private
   * @param {string} url
   * @returns {boolean}
   */
  private isVorticeRequest(url:string): boolean {
    return url.includes('loja/fidelidade');
  }

  private handleVorticeErrors(error: any) {
      //@TODO handle these errors
  }

  /**
   * Check it is a url without http or https prefix
   * @private
   * @param {string} url
   * @returns {boolean}
   */
  private isIncompleteUrl(url: string): boolean {
    return url.match(/http(s)?:/) === null;
  }

  private getFilialID() {
    let filialID = environment.filialId;
    let operador: any = this.localStorageService.get('operador');
    if (operador && operador.filial_id) {
      filialID = operador.filial_id;
    }
    return String(filialID).toString();
  }

  // private getHostname() {
  //   let hostname = window.location.hostname;
  //   hostname = hostname.startsWith('www') ? hostname.replace('www.', '') : hostname;
  //   return hostname;
  // }

  private getWsUrl() {
    return environment.wsUrl;
    // if (environment.production) {
    //   const hostname = this.getHostname();
    //   return `${window.location.protocol}//ws.${hostname}/loja`;
    // }
    // return environment.wsUrl;
  }

  private getApiUrl() {
    return environment.apiUrl;
    // if (environment.production) {
    //   const hostname = this.getHostname();
    //   return `${window.location.protocol}//api.${hostname}/v1`;
    // }
    // return environment.apiUrl;
  }
}
