import { UrlSegment, UrlSegmentGroup } from '@angular/router';
import { CentroDistribuicaoComponent } from './centro-distribuicao/centro-distribuicao.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthGuard } from './shared/guards/auth.guard';

export let routes = [
  // Home
  { path: '', loadChildren: () => import('./produtos').then(m => m.ProdutosModule) },

  //HOME - Supermercado Online
  { matcher: matcherSupermercadoOnline, loadChildren: () => import('./produtos').then(m => m.ProdutosModule) },

  // SHARED LIST
  {
    path: 'compartilhar-lista',
    loadChildren:
      () => import('./compartilhar-lista/compartilhar-lista.module').then(m => m.CompartilharListaModule)
  },

  // MY LISTS
  {
    path: 'minhas-listas',
    loadChildren: () => import('./minhas-listas/minhas-listas.module').then(m => m.MinhasListasModule),
    canActivate: [AuthGuard]
  },

  // TELEVENDAS
  {
    path: 'televendas',
    loadChildren: () => import('./televendas/televendas.module').then(m => m.TelevendasModule)
  },

  // MANUTENCAO
  {
    path: 'manutencao',
    loadChildren: () => import('./manutencao/manutencao.module').then(m => m.ManutencaoModule)
  },

  // ROTAS DE COMPATIBILIDADE COM LOJA ANTIGA [REMOVER]
  { path: 'home', redirectTo: '/produtos/home' },
  {
    path: 'departamento/:departamento/:secao',
    redirectTo: 'produtos/departamento/:departamento/:secao',
    queryParamsHandling: 'preserve'
  },
  {
    path: 'departamento/:departamento',
    redirectTo: 'produtos/departamento/:departamento',
    queryParamsHandling: 'preserve'
  },
  { path: 'produto/:link', redirectTo: 'produtos/detalhe/:link' },
  {
    path: 'ofertas/:departamento',
    redirectTo: 'produtos/ofertas/departamento/:departamento',
    queryParamsHandling: 'preserve'
  },
  {
    path: 'meus-favoritos/:departamento',
    redirectTo: '',
    queryParamsHandling: 'preserve'
  },
  {
    path: 'combos/:departamento',
    redirectTo: 'Produtos/combos/:departamenteo',
    queryParamsHandling: 'preserve'
  },
  {
    path: 'mais-vendidos/:departamento',
    redirectTo: 'produtos/mais-vendidos/:departamento',
    queryParamsHandling: 'preserve'
  },
  {
    path: 'ofertas-por-tags/:tag',
    redirectTo: 'produtos/ofertas/tags/:tag',
    queryParamsHandling: 'preserve'
  },
  {
    path: 'buscas',
    redirectTo: 'produtos/buscas',
    queryParamsHandling: 'preserve'
  },
  {
    path: 'colecoes/:slug',
    redirectTo: 'produtos/colecoes/:slug',
    queryParamsHandling: 'preserve'
  },
  {
    path: 'listas/:slug',
    redirectTo: 'produtos/listas/:slug',
    queryParamsHandling: 'preserve'
  },
  {
    path: 'banners/:id',
    redirectTo: 'produtos/ofertas/banner/:id',
    queryParamsHandling: 'preserve'
  },
  {
    path: 'finalizar-carrinho',
    redirectTo: 'checkout/carrinho',
    queryParamsHandling: 'preserve'
  },
  {
    path: 'finalizar-entrega',
    redirectTo: 'checkout/entrega',
    queryParamsHandling: 'preserve'
  },
  {
    path: 'finalizar-pagamento',
    redirectTo: 'checkout/pagamento',
    queryParamsHandling: 'preserve'
  },
  {
    path: 'finalizar-pagamento-retorno/:id/:pg',
    redirectTo: 'checkout/retorno/:id/:pg',
    queryParamsHandling: 'preserve'
  },
  {
    path: 'finalizar-resumo/:id',
    redirectTo: 'checkout/resumo/:id',
    queryParamsHandling: 'preserve'
  },
  { path: 'sorteios', loadChildren: () => import('./sorteios/sorteios.module').then(m => m.SorteiosModule) },

  // ROTAS DE COMPATIBILIDADE COM LOJA ANTIGA [REMOVER]
  { path: 'produtos', loadChildren: () => import('./produtos').then(m => m.ProdutosModule) },
  { path: 'receitas', loadChildren: () => import('./receitas/receitas.module').then(m => m.ReceitasModule) },
  {
    path: 'institucional',
    loadChildren: () => import('./institucional').then(m => m.InstitucionalModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./checkout').then(m => m.CheckoutModule),
  },
  { path: 'clientes', loadChildren: () => import('./clientes').then(m => m.ClientesModule) },
  { path: 'i/:codigo', redirectTo: '/clientes/indicacao/:codigo' },
  {
    path: 'minha-conta',
    loadChildren: () => import('./minha-conta').then(m => m.MinhaContaModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'integracao',
    loadChildren: () => import('./iframe/iframe.module').then(m => m.IframeModule),
    canActivate: [AuthGuard]
  },

  { path: 'selecionar/centro_distribuicao/:id', component: CentroDistribuicaoComponent },
  { path: '404', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent },
];

export function matcherSupermercadoOnline(url: UrlSegment[], group: UrlSegmentGroup, route) {
  const regex = /\b(retirada|entrega)\/(cep|codigo_ibge)\/\d+\/(cd\/\d+\/)?$/g;
  const urlStr = url ? url.map(url => url.path).reduce((url, item) => (url + '/' + item), '') + '/' : '';

  if (!urlStr.match(regex)) return null;

  const result = ({
    consumed: url, posParams: {
      "operacao": url[0],  //'retirada'|'entrega'
      "tipo_busca": url[1],//'cep'|'codigo_ibge'
      "codigo_busca": url[2],//cep ou codigo do ibge
    }
  });

  if (url[4]) result.posParams["cd_id"] = url[4];

  return result;
}
